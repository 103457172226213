import React, { FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

interface CardProps {
  image: string;
  create?: string;
  replies: string;
  makertcap?: string;
  name: string;
  mint: string;
  symbol?: string;
  className?: string;
  presito?: boolean;
  classTitle?: string;
}

const Card: FunctionComponent<CardProps> = ({
  image,
  create,
  replies,
  name,
  mint,
  symbol,
  makertcap,
  className,
  presito,
  classTitle,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/market/${mint}`)}
      className={`w-full ${className} cursor-pointer outline outline-1 rounded-lg hover:animate-pulse shadow-md hover:outline-green_custom hover:outline-4 transition-all overflow-hidden relative z-10 min-h-36`}
      style={{ minWidth: "38vh" }}
    >
      {presito && (
        <img
          src="/images/moon.png"
          alt="jail.png"
          className="absolute w-full top-0 left-0 opacity-40 h-full -z-10"
        />
      )}
      <motion.div
        initial={{ opacity: 0, right: 100, position: "absolute" }}
        animate={{ opacity: 1, right: 0, position: "relative" }}
        exit={{ opacity: 0 }}
        transition={{ delay: 1.5 }}
        className="bg-transparent w-full h-full p-2 flex justify-between items-start gap-2 relative rounded-lg"
      >
        <img
          src={image}
          alt={image}
          className="4xl:max-w-40 4xl:max-h-36 4xl:min-w-40 4xl:min-h-36 max-w-36 min-w-36 max-h-32 min-h-32 rounded-lg shadow-lg "
        />
        <ul className="list-none font-normal text-sm 4xl:text-lg w-full mr-10 md:mr-0 font-poetsen">
          {create && (
            <li className="flex justify-start items-center max-sm:items-start max-sm:flex-col max-sm:gap-0 gap-2">
              <span
                className={`text-green_custom font-pricedown tracking-wider uppercase font-bold text-xl max-sm:text-xl ${classTitle}`}
              >
                {name}
              </span>
            </li>
          )}
          <li className="truncate flex justify-start items-center gap-4 uppercase">
            <p> Created:</p>
            <Link
              to={`/profile/${create}`}
              className="flex justify-center items-center bg-green_custom outline-green_custom rounded-sm px-2 hover:bg-gray_custom text-white font-pricedown transition-all"
            >
              {create && create.slice(0, 10)}
            </Link>
          </li>
          <li className="line-clamp-2">
            THE TICKER:{" "}
            <span className="text-green_custom uppercase"> {symbol}</span>
          </li>
          <li className="text-sm">
            MARKET CAP:{" "}
            <span className="text-green_custom uppercase"> {makertcap}</span>
          </li>
          <li className="text-sm line-clamp-2 max-sm:line-clamp-2 4xl:text-lg 4xl:line-clamp-1">
            <span className="text-green_custom uppercase"> {replies}</span>
          </li>
        </ul>
        {presito && (
          <img
            src="/images/logo.jpeg"
            alt="bonkmon-face-left.png"
            className="absolute w-44 -top-5 -right-5 max-sm:opacity-25 max-sm:-right-8"
          />
        )}
      </motion.div>
    </div>
  );
};

export default Card;
