import React, { useState } from "react";
import { motion } from "framer-motion";
import Button from "src/Components/Button";

const Staking = () => {
  const [active, setActive] = useState(false);
  const rewards = [
    { name: "Rewards", value: "30%" },
    { name: "TVL", value: "$3,402.12" },
    { name: "unlock", value: "21 days" },
  ];
  return (
    <div className="flex flex-col justify-center items-center gap-4">
      <motion.div
        initial={{ opacity: 0, right: 100, position: "relative" }}
        animate={{ opacity: 1, right: 0, position: "relative" }}
        exit={{ opacity: 0 }}
        transition={{ delay: 1 }}
        className="flex flex-col justify-center items-center gap-2"
      >
        <h2 className="text-6xl font-pricedown tracking-wide uppercase">
          Liquid Staking
        </h2>
        <p className="text-4xl uppercase"> Staked $SBF to earn rewards</p>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 1 }}
        className="flex flex-col justify-center items-start gap-6 w-6/12 border h-full p-6 px-10 rounded-xl bg-black/50 md:w-10/12"
      >
        <div className="flex justify-start items-center gap-4">
          <img
            src="https://dd.dexscreener.com/ds-data/tokens/solana/B582oxHHy41ijxaGem3GksSWAVBfr2fJxNKrL7mpC1z1.png?size=lg&key=5995f3"
            alt="sbf.logo"
            className="rounded-full w-20"
          />
          <div>
            <h2 className="text-4xl font-pricedown tracking-wide">sSBF</h2>
            <p className="text-xl uppercase">Staked sbf </p>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 w-full place-items-center">
          {rewards.map((row, index) => (
            <div key={index} className="w-full text-center">
              <h2 className="text-xl uppercase text-orange-500">{row.name}</h2>
              <p className="uppercase text-4xl font-pricedown">{row.value}</p>
            </div>
          ))}
        </div>
        <div className="outline outline-2 font-pricedown text-4xl uppercase rounded-lg">
          <button
            type="button"
            className={`px-8 py-1 ${
              !active && "bg-white text-black"
            } uppercase rounded-l-md`}
            onClick={() => {
              setActive(false);
            }}
          >
            Stake
          </button>
          <button
            type="button"
            onClick={() => {
              setActive(true);
            }}
            className={`px-8 py-1 ${
              active && "bg-white text-black"
            }  uppercase rounded-r-md`}
          >
            UNStake
          </button>
        </div>
        <div className="w-full bg-black/90 p-4 rounded-xl flex flex-col gap-8">
          <div className="flex justify-between items-center w-full">
            <h2 className="text-xl">BALANCE: 0 sSBF </h2>
            <div className="flex justify-between items-center gap-2 text-xl">
              <p className="bg-orange-600 py-0.5 font-pricedown text-black rounded-md px-4">
                50%
              </p>
              <p className="bg-orange-600 py-0.5 font-pricedown text-black rounded-md px-4">
                MAX
              </p>
            </div>
          </div>

          <h2 className="font-pricedown text-6xl">3400</h2>
          <p>$20.23</p>

          <Button label="Stake" className="text-4xl" />
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 1 }}
        className="flex flex-col justify-center items-start gap-6 w-8/12 md:w-full border h-full p-6 px-10 rounded-xl bg-black/50"
      >
        <h2 className="uppercase font-pricedown tracking-wide text-2xl">
          REWARDS
        </h2>

        <table className="w-full">
          <thead className="font-poetsen">
            <tr>
              <th className="border-b pb-4 text-start">DATE & TIME</th>
              <th className="border-b pb-4 text-start">REWARDS</th>
              <th className="border-b pb-4 text-start">TRANSACTION ID</th>
            </tr>
          </thead>
        </table>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 1 }}
        className="flex flex-col justify-center items-center gap-6 w-8/12 md:w-full h-full p-6 px-10"
      >
        <h2 className="uppercase font-pricedown tracking-wide text-6xl">FAQ</h2>
      </motion.div>

      <div className="group w-8/12 md:w-full">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 1 }}
          className="flex justify-between items-center gap-6 w-full border h-full py-2 px-6 rounded-xl bg-black/50 cursor-pointer"
        >
          <h2 className="text-xl uppercase">
            why is the unstaking period 21 days?
          </h2>
          <i className="fas fa-caret-down text-4xl"></i>
        </motion.div>
        <div className="text-justify px-4 py-4 transition-all">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Debitis
          voluptatum officia sint ipsa neque harum? Necessitatibus eveniet illo
          qui architecto laudantium, consequatur rerum nihil, eaque quae,
          quisquam doloremque deserunt optio. Lorem ipsum dolor sit amet
          consectetur, adipisicing elit. Eligendi ad excepturi expedita magni
          fugiat aliquid ullam optio ex est. Veniam maiores ipsam porro rem.
          Perferendis tempora illo impedit libero quas?
        </div>
      </div>
    </div>
  );
};

export default Staking;
