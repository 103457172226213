import { useWallet } from "@solana/wallet-adapter-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Comments } from "src/Api/interface";
import { getApiMethod } from "src/Api/token";
import { CreatesImage } from "src/utils/funtions";
import Button from "./Button";
import Input from "./Inputs";
import Modal from "./Modal";

const ModalComet = ({
  onClose,
  isOpen,
  mint,
  mention,
}: {
  onClose: () => void;
  isOpen: boolean;
  mint: string;
  mention?: string;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm();
  const [message, setMessage] = useState("");
  const { publicKey } = useWallet();

  useEffect(() => {
    if (mention) {
      setValue("content", `#${mention}, `);
    }
  }, [mention, setValue]);

  const onSubmit = async (data: { content: any; file: File[] }) => {
    if (!publicKey) {
      setMessage("YOU NEED TO CONNECT A WALLET, ROOKIE");
      return setTimeout(() => {
        setMessage("");
      }, 5000);
    }
    setMessage("Saving...");
    const api = new getApiMethod();
    try {
      const res = await api.createCommnet({
        content: data.content,
        mint: mint,
        pubkey: publicKey.toString(),
      });
      const dataComment = res.data as unknown as Comments;
      if (dataComment.id && data.file.length > 0) {
        const arrayImages = await CreatesImage(
          data.file,
          publicKey.toString(),
          (value) => {
            setMessage(value);
          }
        );
        for (let i = 0; i < arrayImages.length; i++) {
          await api.createImageCommnet({
            comment: dataComment.id,
            image: arrayImages[i],
          });
        }
      }
      reset({ file: [], content: null });
      toast.success("SHIT TALKED ", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setMessage("");
      return onClose();
    } catch (error) {
      console.error(error);
      setMessage("Error to saving");
      setTimeout(() => {
        setMessage("");
      }, 5000);
      return toast.error("Commet errror ", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset({ file: [], content: null });
      }}
      className="lg:w-6/12 md:w-8/12 max-sm:w-10/12 shadow-sm dark:shadow-white"
    >
      <h2 className="font-pricedown tracking-wider uppercase text-center text-4xl mb-4">
        TALK SOME SHIT
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex justify-center items-center flex-col gap-4"
      >
        <Input
          name="content"
          label="ADD A COMMENT"
          placeholder="COMMENT"
          rows={4}
          register={register}
          errors={errors.content}
          textarea
          required
        />
        <Input
          name="file"
          label="IMAGES"
          type="file"
          placeholder="(OPTIONAL)"
          rows={4}
          register={register}
          errors={errors.file}
          accept="image/*"
          multiple
        />
        {watch("file") && watch("file").length > 0 && (
          <div className="grid grid-cols-4 place-items-center gap-4 w-full">
            {Array.from(watch("file")).map((file: File, index: number) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Preview ${index + 1}`}
                className="shadow-xl outline outline-orange-600 rounded-2xl h-24 w-24"
              />
            ))}
          </div>
        )}

        <Button
          label="Publish comment"
          loading={message !== ""}
          loadingMesaage={message !== "" && message}
          icon="fas fa-comments"
        />
      </form>
    </Modal>
  );
};

export default ModalComet;
