import AWS, { S3 } from "aws-sdk";

interface UploadParams {
  Bucket: string;
  Key: string;
  Body: Uint8Array;
  ContentType: string;
  ACL: string;
  Metadata?: { [key: string]: string };
}

interface DownloadParams {
  Bucket: string;
  Key: string;
  ACL?: string;
}
interface UploadProgress {
  loaded: number;
  total: number;
}

interface UploadState {
  isLoading: boolean;
  progress: UploadProgress | null;
  fileUrl: string | null;
}

class S3FileManager {
  private s3: S3;
  private spaceName: string;
  private targetFolder: string;

  constructor(user) {
    this.s3 = new S3({
      accessKeyId: process.env.DIGITAL_KEY_ID,
      secretAccessKey: process.env.DIGITAL_SECRET,
      region: "nyc1",
      endpoint: new AWS.Endpoint(process.env.DIGITAL_ENDPOINT),
      s3ForcePathStyle: true,
    });
    this.spaceName = process.env.DIGITAL_FOLDER;
    this.targetFolder = user.slice(0, 10);
  }

  public async uploadFile(
    file: File,
    onProgress: (progress: number) => void
  ): Promise<string> {
    const uint8Array = new Uint8Array(await file.arrayBuffer());
    const totalBytes = uint8Array.byteLength;

    const uploadParams: UploadParams = {
      Bucket: this.spaceName,
      Key: `${this.targetFolder}/${file.name}`,
      Body: uint8Array,
      ContentType: file.type,
      ACL: "public-read",
      Metadata: {
        "Access-Control-Allow-Origin": "*",
      },
    };

    const uploadState: UploadState = {
      isLoading: true,
      progress: null,
      fileUrl: null,
    };

    try {
      const data = await this.s3
        .upload(uploadParams, { partSize: 10 * 1024 * 1024 })
        .on("httpUploadProgress", (progress) => {
          const loadedBytes = progress.loaded;
          const percentUploaded = Math.round((loadedBytes / totalBytes) * 100);
          onProgress(percentUploaded);
        })
        .promise();

      uploadState.isLoading = false;
      uploadState.fileUrl = data.Location;
      console.log("File location: " + data.Location);
      return data.Location;
    } catch (err) {
      console.error("Error uploading file:", err);
      throw err;
    }
  }

  public async deleteFileByUrl(fileUrl: string): Promise<void> {
    const urlParts = fileUrl.split("/");
    const fileName = urlParts[urlParts.length - 1];

    const deleteParams: DownloadParams = {
      Bucket: this.spaceName,
      Key: `${this.targetFolder}/${fileName}`,
    };

    try {
      await this.s3.deleteObject(deleteParams).promise();
      console.log("File deleted successfully:", fileName);
    } catch (err) {
      console.error("Error deleting file:", err);
      throw err;
    }
  }
}

export default S3FileManager;
