import { getApiMethod } from "src/Api/token";

const datafeed = async (mint: string, price: string) => {
  if (!mint) return;
  const api = new getApiMethod();
  const dataToken = await api.getTokensById(mint);
  const tx = await api.getTraderData(mint);
  const from = Math.floor(new Date(dataToken?.created_at).getTime() / 1000);
  const to = tx?.[0]?.time
    ? tx?.[0]?.time
    : Math.floor(new Date(dataToken?.created_at).getTime() / 1000);
  const data = await api.getChartMarketById(mint, from, to);
  const repeatData = [];
  const totalValues = data?.length < 300 ? 300 : data?.length;
  const interval = (to - from) / totalValues;
  let currentTimestamp = from;
  let isPass = true;
  let lastValidData = null;

  for (let i = 0; i < totalValues; i++) {
    const existingData =
      data.length === 0
        ? {
            time: Math.floor(new Date(dataToken.created_at).getTime() / 1000),
            open: 0,
            high: price,
            close: price,
            low: 0,
          }
        : data.find(
            // eslint-disable-next-line no-loop-func
            (item) =>
              item.time >= currentTimestamp &&
              item.time < currentTimestamp + interval &&
              item.high !== null
          );

    if (existingData) {
      repeatData.push(existingData);
      lastValidData = existingData; // Actualizar el último dato válido
    } else {
      if (lastValidData) {
        // Si hay un último dato válido, usarlo para llenar los datos faltantes
        repeatData.push({ ...lastValidData, time: currentTimestamp * 1000 });
      } else {
        // Si no hay último dato válido, llenar con el último dato disponible en data
        const lastData = data[data.length - 1];
        repeatData.push({ ...lastData, time: currentTimestamp });
      }
    }
    currentTimestamp += interval;
  }

  const configurationData = {
    supported_resolutions: ["5"],
    exchanges: [
      {
        value: dataToken.mint,
        name: dataToken.name,
        desc: dataToken.description,
      },
    ],
    symbols_types: [
      {
        name: dataToken.symbol,
        value: dataToken.mint,
      },
    ],
  };

  return {
    token: dataToken,
    dataFeed: {
      onReady: (callback) => {
        setTimeout(() => callback(configurationData));
      },
      searchSymbols: (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
      ) => {
        console.log("[searchSymbols]: Method call");
        onResultReadyCallback(dataToken.symbol);
      },
      resolveSymbol: (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
      ) => {
        const symbolInfo = {
          ticker: dataToken.symbol,
          name: dataToken.name,
          description: dataToken.symbol,
          type: "crypto",
          session: "24x7",
          timezone: "Etc/UTC",
          exchange: dataToken.mint,
          minmov: 0,
          pricescale: 1000000000000,
          has_intraday: true,
          visible_plots_set: "ohlc",
          has_weekly_and_monthly: false,
          supported_resolutions: configurationData.supported_resolutions,
          volume_precision: 2,
          data_status: "streaming",
        };
        onSymbolResolvedCallback(symbolInfo);
      },
      getBars: (
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback
      ) => {
        if (isPass === true) {
          try {
            onHistoryCallback(repeatData, { noData: false });
            // console.log("[getBars]: Method call", symbolInfo, resolution);
          } catch (error) {
            console.log("[getBars]: Get error", error);
            onErrorCallback(error);
          }
          isPass = false;
        }
      },
    },
  };
};

export default datafeed;
