import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import {
  useWalletModal,
  WalletModalProvider,
} from "@solana/wallet-adapter-react-ui";
import { BtnConnect } from "../Components/Buttoncone";
import { useWallet } from "@solana/wallet-adapter-react";
import Button from "../Components/Button";
import Modal from "src/Components/Modal";
import { useForm } from "react-hook-form";
import RandomImageDropdown from "src/Components/RamdomImage";
import Input from "src/Components/Inputs";
import { User } from "src/Api/interface";
import { getApiMethod } from "src/Api/token";
import { CreateImage } from "src/utils/funtions";
import { toast } from "react-hot-toast";
import TabMenu from "src/Components/TabMenu.mobile";
import { motion } from "framer-motion";

const Navbar = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [random, setRandom] = useState(false);
  const [dataUser, setDataUSer] = useState<User | null>(null);
  const [instructions, setInstructions] = useState(false);
  const [active, setActive] = React.useState("");
  const { setVisible: setModalVisible } = useWalletModal();
  const [message, setMessage] = useState("");

  const getAsyncData = useCallback(async () => {
    const api = new getApiMethod();
    try {
      const params = new URLSearchParams(location.search.replace("?", ""));
      const referParam = params.get("refer");
      const data = await api.getUser(publicKey.toString());
      setDataUSer(data as User);
      setValue("username", data.username);
      setValue("description", data.description);
      setValue("image", data.image);
      setValue("refer", referParam || data.referred_by);
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
      setIsModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    if (!publicKey) return;

    getAsyncData();
  }, [getAsyncData, publicKey]);

  useEffect(() => {
    setActive(location.pathname.split("/")[1]);
    const params = new URLSearchParams(location.search.replace("?", ""));
    const referParam = params.get("refer");
    if (referParam) {
      document.getElementById("btnConnect").click();
    }
  }, [location, setModalVisible]);

  const closeModal = () => {
    setIsModalOpen(false);
    if (!publicKey) return;
    if (dataUser === null) {
      setIsModalOpen(true);
    }
  };
  const onSubmit = async (data: any) => {
    if (!publicKey)
      return toast.error("YOU NEED TO CONNECT A WALLET, ROOKIE", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    setMessage("Getting info...");
    const api = new getApiMethod();
    const { username, description, file, image } = data;

    let uri;
    const isImageUrl = /^(http|https):\/\/[^ "]+$/.test(image);
    if (dataUser && dataUser.image === image) {
      uri = image;
    } else if (isImageUrl) {
      uri = image;
    } else if (file && file.length > 0) {
      try {
        uri = await CreateImage(file[0], publicKey.toBase58(), (value) => {
          setMessage(value);
        });
      } catch (error) {
        setMessage("Error to upoad...");
      }
    } else {
      uri = undefined;
    }
    setMessage("Saving user info...");

    if (dataUser) {
      const update = {
        username,
        description,
        image: uri,
      };
      try {
        await api.updateUser(dataUser.pubkey, update);
        setMessage("The profile update was successful!");
        toast.success("The profile update was successful!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        getAsyncData();
        return setTimeout(() => {
          setMessage("");
        }, 3000);
      } catch (error) {
        setMessage("There was an issue encountered while update the profile!");
        toast.error(
          "There was an issue encountered while update the profile!",
          {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          }
        );

        console.error(error);
        return setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    }

    if (uri) {
      const params = new URLSearchParams(location.search.replace("?", ""));
      const referParam = params.get("refer");
      try {
        if (referParam) {
          await api.createUser({
            description,
            username,
            image: uri,
            pubkey: publicKey.toString(),
            followers: 0,
            referred_by: referParam,
          });
        } else {
          await api.createUser({
            description,
            username,
            image: uri,
            pubkey: publicKey.toString(),
            followers: 0,
          });
        }
        setMessage("The profile creation was successful!");
        toast.success("The profile creation was successful!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        getAsyncData();
        return setTimeout(() => {
          setMessage("");
        }, 3000);
      } catch (error) {
        setMessage(
          "There was an issue encountered while creating the profile!"
        );
        toast.error(
          "There was an issue encountered while creating the profile!",
          {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          }
        );
        console.error(error);
        return setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    }
  };

  return (
    <WalletModalProvider>
      <div>
        <div className="relative overflow-x-hidden">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1 }}
            className="relative z-20 max-sm:z-10 top-0 left-0 flex justify-between items-center max-sm:items-end w-full p-4 max-sm:px-2 bg-zinc-100 text-gray_custom"
          >
            <Link
              to={"/"}
              className="text-5xl leading-0 cursor-pointer font-extrabold font-pricedown2"
            >
              <img src="/images/logoap.png" alt="logo" className="w-44" />
            </Link>
            <div>
              <BtnConnect
                dataUser={dataUser}
                openModal={() => setIsModalOpen(true)}
                id="btnConnect"
              />
            </div>
          </motion.div>
          <section className="relative p-4  text-gray_custom h-full min-h-screen overflow-x-hidden overflow-y-hidden">
            <div className="group mb-10 max-sm:mb-0 w-full flex justify-end items-end">
              <div
                className={`bg-white flex justify-between items-start rounded-r-2xl w-0 fixed top-16 z-50 p-4 overflow-hidden -left-96 transition-all -translate-x-96 duration-300 shadow-lg max-sm:w-full max-sm:flex-col ${
                  instructions &&
                  "-left-14 md:-left-10 max-sm:-left-10 translate-x-0 4xl:w-2/12 lg:w-4/12 md:w-6/12 max-md:w-7/12 "
                }`}
              >
                <img
                  src="/images/logo.jpeg"
                  alt="logo.jpeg"
                  className="w-80 absolute -top-5 -left-8 max-sm:opacity-20 md:opacity-20"
                />
                <i
                  className="fas fa-xmark absolute text-xl text-gray_custom z-50 right-3 top-3 cursor-pointer"
                  onClick={() => setInstructions(false)}
                ></i>
                <p className="max-sm:text-start text-sm ml-60 max-sm:ml-10 md:ml-10 mr-5 font-poetsen">
                  <span className="text-justify w-full">
                    Som Bonkmon Fraud prevents rugs by making sure that all
                    created tokens are safe. Each coin on sbf.fun is a
                    fair-launch with no presale and no team allocation. Dev must
                    buy just like everyone else.{" "}
                  </span>
                  <br /> <br /> step 1: pick a coin that you like. <br /> step
                  2: buy the coin on the bonding curve.
                  <br />
                  step 3: sell at any time to lock in profits or losses.
                  <br /> step 4: when enough people buy on the bonding curve it
                  reaches a market cap of $69k.
                  <br /> step 5: $12k of liquidity is then deposited in raydium
                  and burned.
                  <br />
                  <br />
                  Disclaimer: Do not forget that creating tokens / buying into
                  hustles can be a high risk activity.
                </p>
              </div>
            </div>
            <div className="fixed opacity-10 top-0 left-0">
              <img
                src="/images/moon.png"
                alt="moon.png"
                className="w-screen h-screen max-sm:w-full"
              />
            </div>
            <div className="relative z-10">
              <Outlet></Outlet>
            </div>
          </section>
          <Footer onClick={() => setInstructions(!instructions)} />
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            className="w-4/12 max-sm:w-10/12 shadow-sm shadow-gray_custom"
          >
            <h2 className="font-semibold text-4xl mb-4 text-center max-sm:text-2xl font-pricedown uppercase tracking-wider">
              Edit Profile
            </h2>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-4 justify-center  items-center"
            >
              <div className="flex flex-col items-center justify-center w-full gap-4">
                <button
                  type="button"
                  className="max-sm:w-full max-sm:h-56 w-40 h-40 relative cursor-pointer group max-sm:flex max-sm:justify-center"
                >
                  <img
                    src={watch("image") || "/images/logo.jpeg"}
                    alt="logo.png"
                    className="max-sm:w-56 max-sm:h-56 w-40 h-40 rounded-full shadow-md"
                  />
                  <div className="max-sm:w-full max-sm:h-56 w-40 h-40  rounded-full bg-gray-800/50 absolute top-0 z-10 scale-0 group-hover:scale-100" />
                  <i className="fas fa-pen-to-square text-xl absolute -bottom-1 left-0 z-10 text-gray_custom"></i>
                  <label className="cursor-pointer">
                    <Input
                      name="file"
                      className="absolute top-0 h-full w-full left-0 z-30 opacity-0 cursor-pointer"
                      type="file"
                      accept="image/jpeg,image/jpg"
                      register={register}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (watch("file")) {
                          const imageUrl = URL.createObjectURL(file);
                          setValue("image", imageUrl);
                        } else {
                          setValue("image", undefined);
                        }
                      }}
                      required={
                        !watch("image") &&
                        !(watch("file") && watch("file").length > 0)
                      }
                    />
                  </label>
                </button>
                {errors.file && (
                  <div className="text-green_custom top-0 font-normal text-sm w-full my-1">
                    {errors.file && (
                      <>
                        <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                        {errors.file.message}
                      </>
                    )}
                  </div>
                )}
                <Button
                  label="Random Image"
                  className="truncate outline outline-2 outline-green_custom rounded-lg font-pricedown uppercase tracking-wider text-xl"
                  color="bg-transparent"
                  type="button"
                  onClick={() => {
                    setRandom(!random);
                  }}
                />
              </div>
              <RandomImageDropdown
                onChange={(value) => {
                  setValue("image", value);
                  setRandom(false);
                }}
                className="hidden"
                count={4}
                random={random}
              />
              {watch("refer") && dataUser && dataUser.userRef && (
                <div className="flex flex-col justify-center items-start w-full">
                  <h2 className="uppercase font-pricedown tracking-wider text-green_custom">
                    Refer
                  </h2>
                  <Link
                    to={`/profile/${dataUser.userRef.pubkey}`}
                    className="flex justify-start items-center outline outline-2 outline-green_custom rounded-full overflow-hidden"
                  >
                    <img
                      className="w-10 h-10"
                      src={dataUser.userRef.image}
                      alt={dataUser.userRef.image}
                    />
                    <h2 className="uppercase font-pricedown tracking-wider px-6">
                      {dataUser.userRef.username}
                    </h2>
                  </Link>
                </div>
              )}

              <Input
                label="Username"
                register={register}
                name="username"
                errors={errors.username}
                required
              />
              <Input
                label="Bio"
                register={register}
                name="description"
                className="h-20"
                textarea
                row={4}
                placeholder="(Optional)"
              />
              <Button
                label="Save"
                icon="fa-solid fa-floppy-disk"
                className="truncate outline outline-2 outline-green_custom rounded-lg font-pricedown uppercase tracking-wider text-xl"
                color="bg-transparent"
                loading={message !== ""}
                loadingMesaage={message !== "" && message}
              />
            </form>
          </Modal>
          {active !== "market" && (
            <TabMenu
              menu={
                publicKey
                  ? [
                      {
                        class: "",
                        icon: "fas fa-home",
                        name: "Home",
                        onClick: () => {
                          navigate(`/`);
                        },
                      },
                      {
                        class: "",
                        icon: "fas fa-coins",
                        name: "Create",
                        onClick: () => {
                          navigate(`/create`);
                        },
                      },
                      {
                        class: "",
                        icon: "fas fa-edit",
                        name: "Edit",
                        onClick: () => {
                          setIsModalOpen(true);
                        },
                      },
                      {
                        class: "",
                        image: dataUser && dataUser.image,
                        icon: "fas fa-user",
                        name: "User",
                        onClick: () => {
                          if (!publicKey) return;
                          navigate(`/profile/${publicKey.toString()}`);
                        },
                      },
                    ]
                  : [
                      {
                        class: "",
                        icon: "fas fa-home",
                        name: "Home",
                        onClick: () => {
                          navigate(`/`);
                        },
                      },
                      {
                        class: "",
                        icon: "fas fa-coins",
                        name: "Create",
                        onClick: () => {
                          navigate(`/create`);
                        },
                      },
                    ]
              }
              buttonCenter={{
                class: "",
                icon: "fas fa-arrow-right-arrow-left",
                name: "trading",
              }}
              children={
                <BtnConnect
                  dataUser={dataUser}
                  isMobile={true}
                  openModal={() => setIsModalOpen(true)}
                />
              }
              onClick={(value) => {}}
            />
          )}
        </div>
      </div>
    </WalletModalProvider>
  );
};

export default Navbar;
