import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Input from "../Components/Inputs";
import Button from "../Components/Button";
import { useForm } from "react-hook-form";
import { useWallet } from "@solana/wallet-adapter-react";
import { useCounterProgram } from "../utils/setup";
import toast from "react-hot-toast";
import { CreateImage, handleImageChange } from "src/utils/funtions";
import ReactAudioPlayer from "react-audio-player";
import { motion } from "framer-motion";

const Create = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();
  const { createToken } = useCounterProgram();
  const { publicKey } = useWallet();
  const [more, setMore] = useState(false);
  const [playSound, setPlaySound] = React.useState({
    sound: "",
    active: false,
  });
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  useEffect(() => {
    setValue("solamount", 70);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: any) => {
    if (!publicKey) {
      setMessage("A wallet is required to continue");
      return setTimeout(() => {
        setMessage("");
      }, 5000);
    }
    setMessage("Getting Info...");
    const {
      name,
      ticker,
      file,
      description,
      telegram,
      twitter,
      website,
      solamount,
    } = data;

    let selectedImage;
    if (file && file.length > 0) {
      try {
        const resizedImageBlob = await handleImageChange(file[0]);
        const name = file[0].name.slice(0, 10);
        const ext = file[0].name.split(".").pop();
        const namefile = name + "." + ext;
        selectedImage = await CreateImage(
          new File([resizedImageBlob], namefile, { type: file[0].type }),
          publicKey.toBase58(),
          (value) => {
            setMessage(value);
          }
        );
        setMessage("Uploaded image");
        setMessage("");
      } catch (error) {
        console.error(error);
        setMessage("Error to upload image...");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    } else {
      selectedImage = undefined;
    }

    const uri = selectedImage;
    if (selectedImage) {
      setMessage("Creating coin...");
      try {
        const POST = await createToken({
          name,
          symbol: ticker,
          uri,
          description,
          telegram,
          website,
          twitter,
          solamount,
        });
        toast(POST?.message || "", {
          icon: POST?.type === "error" ? "❌" : "✅",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        POST?.type === "error"
          ? setPlaySound({ sound: "/audio/error.mp3", active: true })
          : setPlaySound({ sound: "/audio/create.mp3", active: true });
        setMessage(
          (POST?.type === "error" ? "❌" : "✅") + " " + POST?.message || ""
        );
        if (POST?.type === "error") {
          return setTimeout(() => {
            setMessage("");
          }, 5000);
        }
        reset({
          name: null,
          ticker: null,
          image: undefined,
          file: undefined,
          description: null,
        });
        setTimeout(() => {
          setMessage("Redirecting to coin...");
        }, 3000);
        return setTimeout(() => {
          navigate(`/market/${POST.mint}`);
        }, 8000);
      } catch (error) {
        console.error(error);
        setMessage("Error to creating coin...");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    }
  };

  return (
    <div className="w-full flex justify-center items-center">
      {playSound.active && (
        <ReactAudioPlayer
          src={playSound.sound}
          autoPlay={true}
          controls={false}
          onEnded={() => setPlaySound({ sound: "", active: false })}
        />
      )}
      <div className="flex flex-col gap-4 justify-center items-start my-4 md:w-8/12 lg:w-4/12 max-sm:w-full">
        <motion.div
          initial={{ opacity: 0, right: 100, position: "relative" }}
          animate={{ opacity: 1, right: 0, position: "relative" }}
          exit={{ opacity: 0 }}
          transition={{ delay: 1 }}
        >
          <Link
            to={"/"}
            className="bg-white text-black transition-all p-2 px-10 text-xl rounded-lg font-normal relative uppercase font-pricedown tracking-wider hover:outline outline-2 outline-greentext-green_custom hover:outline-4 hover:text-green_custom"
          >
            <i className="fas fa-angle-left absolute left-3 top-3"></i> Go back
          </Link>
        </motion.div>

        <motion.form
          initial={{ opacity: 0, left: 100, position: "relative" }}
          animate={{ opacity: 1, left: 0, position: "relative" }}
          exit={{ opacity: 0 }}
          transition={{ delay: 1 }}
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-center items-start gap-6 w-full"
        >
          <Input
            label="Name"
            name="name"
            minLength="5"
            maxLength="15"
            type="text"
            register={register}
            errors={errors.name}
            required
          />

          <Input
            label="TICKER - TOKEN ABBREVIATION"
            name="ticker"
            minLength="3"
            maxLength="6"
            register={register}
            errors={errors.ticker}
            required
          />
          <Input
            label="Description"
            name="description"
            textarea={true}
            rows={4}
            register={register}
            errors={errors.description}
            required
          />
          <div className="flex flex-row items-center justify-center w-full gap-4">
            <button
              type="button"
              className="w-36 h-36 relative cursor-pointer group"
            >
              <img
                src={watch("image") || "/images/logo.jpeg"}
                alt="logo.png"
                className="w-36 h-36 rounded-md shadow-md"
              />
              <div className="w-36 h-36  rounded-md bg-gray-800/50 absolute top-0 z-10 scale-0 group-hover:scale-100" />
              <i className="fas fa-pen-to-square text-xl absolute -bottom-1 left-0 z-10 text-green_custom "></i>

              <Input
                name="file"
                className="absolute top-0 h-36 w-36 left-0 z-30 opacity-0 cursor-pointer"
                type="file"
                accept="image/jpeg,image/jpg"
                register={register}
                onChange={(e) => {
                  const file = e.target.files[0];
                  const allowedTypes = ["image/jpeg", "image/jpg"];
                  if (file && allowedTypes.includes(file.type)) {
                    const imageUrl = URL.createObjectURL(file);
                    setValue("image", imageUrl);
                  } else {
                    setValue("image", undefined);
                  }
                }}
                required={
                  !watch("image") &&
                  !(watch("file") && watch("file").length > 0)
                }
              />

              <label className="cursor-pointer font-pricedown text-green_custom uppercase">
                Token Image
              </label>
              {errors.file && (
                <div className="text-green_custom font-pricedown font-normal text-sm w-full my-1">
                  {errors.file && (
                    <>
                      <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                      {errors.file.message}
                    </>
                  )}
                </div>
              )}
            </button>
          </div>

          <button
            type="button"
            className="text-green_custom font-pricedown uppercase"
            onClick={() => setMore(!more)}
          >
            Show more options <i className="fas fa-arrow-down"></i>
          </button>

          <div
            className={`w-full flex flex-col gap-4 transition-all ${
              more ? "" : "h-0 opacity-0 "
            }`}
          >
            <Input
              label="Twitter link"
              name="twitter"
              type="text"
              placeholder="(Optional)"
              register={register}
            />
            <Input
              label="Telegram link"
              name="telegram"
              type="text"
              placeholder="(Optional)"
              register={register}
            />
            <Input
              label="Website"
              name="website"
              type="text"
              placeholder="(Optional)"
              register={register}
            />
            <label className="uppercase text-green_custom font-pricedown tracking-wider">
              Bonding sol amount
            </label>
            <div
              className={`flex justify-between items-center border-2 border-greentext-green_custom  rounded-lg w-full ${
                errors.solamount && "border-4"
              }`}
            >
              <button
                type="button"
                className="flex justify-center items-center w-full border-r-2 py-2 border-greentext-green_custom"
                onClick={() => {
                  const value = Math.max(Number(watch("solamount")) - 1, 10);
                  setValue("solamount", value);
                }}
              >
                <i className="fas fa-minus"></i>
              </button>
              <Input
                name="solamount"
                type="number"
                min={10}
                className="text-xl py-2  font-pricedown text-center outline-transparent"
                register={register}
                errors={errors.solamount}
                required
              />
              <button
                type="button"
                className="flex justify-center items-center w-full py-2 border-l-2 border-greentext-green_custom"
                onClick={() => {
                  const value = Number(watch("solamount")) + 1;
                  setValue("solamount", value);
                }}
              >
                <i className="fas fa-plus"></i>
              </button>
            </div>
          </div>

          <Button
            label="Create Coin"
            img="/images/icons/save.svg"
            className={`w-full transition-all ${
              !more && "-translate-y-6"
            } bg-green_custom text-gray_custom hover:text-green_custom hover:bg-white tracking-widest`}
            type="submit"
            loading={message !== ""}
            loadingMesaage={message !== "" && message}
            classImg="bg-neutral-700 rounded-full"
          />
          <span
            className={`flex justify-center items-center w-full gap-2 pointer-events-none transition-all font-pricedown uppercase tracking-wider ${
              !more && "-translate-y-6"
            }`}
          >
            Cost to deploy: <b>~0.02 SOL</b>
          </span>
        </motion.form>
      </div>
    </div>
  );
};

export default Create;
