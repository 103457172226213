import React from "react";
import { motion } from "framer-motion";

const Jail = ({ fixed }: { fixed?: boolean }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0 }}
      className={`${
        fixed ? "" : "fixed"
      } top-0 z-50 right-0 w-full h-full max-sm:hidden transition-all duration-300 delay-100 animate-slide-left flex justify-center items-center bg-white/60`}
    >
      {/* <img src="/images/jail.png" alt="jail.png" className="w-full h-full" /> */}
      <div className="containerNave">
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
      </div>
    </motion.div>
  );
};

export default Jail;
