import React, { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCounterProgram } from "src/utils/setup";
import { toast } from "react-hot-toast";
import Button from "./Button";
import Input from "./Inputs";
import { Token } from "src/Api/interface";
import { useWallet } from "@solana/wallet-adapter-react";
import Modal from "./Modal";
import AudioPlayer from "react-audio-player";

interface FormTradingProps {
  getAsyncData: () => void;
  data: Token;
  price: string;
  mint: string;
  tokenReserve: number;
  className?: string;
}

const FormTrading = memo<FormTradingProps>(
  ({ getAsyncData, data, price, mint, tokenReserve, className }) => {
    const {
      register,
      handleSubmit,
      setValue,
      watch,
      reset,
      formState: { errors },
    } = useForm({
      defaultValues: { amount: 0, event: "buy", totalAmount: 0, slippage: 5 },
    });
    const [swicht, setSwicht] = useState(false);
    const [slippage, setSlippage] = useState(false);
    const [playSound, setPlaySound] = React.useState({
      sound: "",
      active: false,
    });
    const { EvenTrandingToken } = useCounterProgram();
    const [message, setMessage] = useState("");
    const { publicKey } = useWallet();
    const { getBalanceToke } = useCounterProgram();

    const getBalance = useCallback(async () => {
      if (!publicKey) return;

      if (mint) {
        try {
          const balance = await getBalanceToke(mint, publicKey.toString());
          console.log(balance);
          setValue("totalAmount", balance || 0);
        } catch (error) {
          console.error(error);
        }
      }
    }, [getBalanceToke, mint, publicKey, setValue]);

    useEffect(() => {
      getBalance();
    }, [getBalance]);

    const onSubmit = async (value: any) => {
      if (!publicKey) {
        setMessage("A wallet is required to continue");
        return setTimeout(() => {
          setMessage("");
        }, 5000);
      }
      const { event, amount, slippage } = value;
      if (event === "buy" || event === "sell") {
        let amoout;
        if (swicht === false) {
          amoout = (amount / parseFloat(price)).toFixed(6);
        } else {
          amoout = amount;
        }
        const txn = await EvenTrandingToken(
          data,
          amoout,
          event,
          slippage,
          (value) => {
            setMessage(value);
          }
        );
        toast.success(txn?.message || "", {
          icon: txn?.type === "error" ? "❌" : "✅",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        txn?.type === "error"
          ? setPlaySound({ sound: "/audio/error.mp3", active: true })
          : setPlaySound({ sound: "/audio/success.mp3", active: true });
        setMessage((txn?.type === "error" ? "❌ " : "✅ ") + txn?.message);
        setTimeout(() => {
          setMessage("");
        }, 5000);
        reset({ amount: 0, event: event, slippage: slippage });
        getBalance();
        return getAsyncData();
      }
    };
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${className} bg-white rounded-lg shadow-md flex flex-col justify-start items-start gap-4 p-4 w-full outline outline-2 outline-green_custom`}
      >
        {playSound.active && (
          <AudioPlayer
            src={playSound.sound}
            autoPlay={true}
            controls={false}
            onEnded={() => setPlaySound({ sound: "", active: false })}
          />
        )}
        <div className="grid grid-cols-2 w-full gap-4">
          <Button
            label="Buy"
            type="button"
            color={`${
              watch("event") === "buy"
                ? "bg-green_custom text-white"
                : "bg-white outline outline-2 outline-green_custom hover:outline-4 text-gray_custom "
            } hover:bg-green-600  hover:bg-green-600 hover:text-white`}
            className="uppercase font-pricedown tracking-wider text-lg"
            onClick={() => {
              setValue("event", "buy");
              setSwicht(false);
            }}
          />
          <Button
            label="Sell"
            type="button"
            color={`${
              watch("event") === "sell"
                ? "bg-green_custom text-white"
                : "bg-white outline outline-2 outline-green_custom hover:outline-4 text-gray_custom"
            } hover:bg-red-600  hover:bg-red-600 hover:text-white`}
            className="uppercase font-pricedown tracking-wider text-lg"
            onClick={() => {
              setValue("event", "sell");
              setSwicht(true);
            }}
          />
        </div>
        <div className="flex justify-between w-full items-center gap-2 text-center text-sm">
          {watch("event") === "buy" && (
            <button
              onClick={() => {
                setSwicht(!swicht);
                if (swicht === false) {
                  const newValue = Number(watch("amount") / parseFloat(price));
                  setValue("amount", newValue);
                } else {
                  const newValue = Number(watch("amount") * parseFloat(price));
                  setValue("amount", newValue);
                }
              }}
              type="button"
              className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-1 flex justify-center items-center gap-1"
            >
              switch to {!swicht ? data && data.symbol : "SOL"}{" "}
              <img
                src={
                  !swicht ? data && data.ipfs_url : "/images/icons/chains.svg"
                }
                alt="chains.svg"
                className="shadow-lg w-6 h-6 rounded-full"
              />
            </button>
          )}
          {watch("event") === "sell" && <div></div>}
          <button
            type="button"
            onClick={() => setSlippage(true)}
            className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-1 px-2"
          >
            Set max slippage
          </button>
        </div>
        <div className="relative w-full">
          <Input
            label="Amount"
            name="amount"
            type="text"
            autoComplete={"off"}
            className="py-2 text-xl font-pricedown"
            register={register}
            errors={errors.amount}
            numericalOnly={true}
            onChange={(e: any) => {
              setValue("amount", e.target.value);
              if (
                watch("event") === "buy" &&
                swicht === false &&
                tokenReserve < watch("amount") * 10 ** 6
              ) {
                toast(
                  (t) => (
                    <p className="font-pricedown uppercase tracking-wider flex flex-col justify-end gap-4 items-end w-full">
                      Only X tokens are available{" "}
                      {tokenReserve.toLocaleString()} {data && data.symbol}?
                    </p>
                  ),
                  {
                    icon: "⚠️",
                    style: {
                      borderRadius: "10px",
                      background: "#333",
                      color: "#fff",
                    },
                  }
                );
                setValue("amount", tokenReserve * parseFloat(price));
              } else if (
                watch("event") === "buy" &&
                swicht === true &&
                tokenReserve < watch("amount")
              ) {
                toast(
                  (t) => (
                    <p className="font-pricedown uppercase tracking-wider flex flex-col justify-end gap-4 items-end w-full">
                      Only X tokens are available{" "}
                      {tokenReserve.toLocaleString()} {data && data.symbol}?
                    </p>
                  ),
                  {
                    icon: "⚠️",
                    style: {
                      borderRadius: "10px",
                      background: "#333",
                      color: "#fff",
                    },
                  }
                );
                setValue("amount", tokenReserve);
              }
            }}
            required
          />
          <div className="flex justify-center items-center absolute right-0.5 top-9 -translate-y-0.5 gap-2 font-normal text-lg font-pricedown tracking-wider">
            {swicht ? data && data.symbol : "SOL"}
            <img
              src={swicht ? data && data.ipfs_url : "/images/icons/chains.svg"}
              alt="chains.svg"
              className="shadow-lg w-10 h-10 rounded-full"
            />
          </div>
        </div>
        {!swicht && watch("event") === "buy" ? (
          <div className="grid grid-cols-4 gap-2 text-center text-sm w-8/12 max-sm:w-full">
            <button
              type="button"
              onClick={() =>
                reset({
                  amount: 0,
                  event: watch("event"),
                  totalAmount: watch("totalAmount"),
                  slippage: watch("slippage"),
                })
              }
              className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-0.5"
            >
              reset
            </button>
            <button
              type="button"
              onClick={() => {
                reset({
                  amount: 1,
                  event: watch("event"),
                  totalAmount: watch("totalAmount"),
                  slippage: watch("slippage"),
                });
                if (
                  watch("event") === "buy" &&
                  swicht === false &&
                  tokenReserve < watch("amount") * 10 ** 6
                ) {
                  toast(
                    (t) => (
                      <p className="font-pricedown uppercase tracking-wider flex flex-col justify-end gap-4 items-end w-full">
                        Only X tokens are available{" "}
                        {tokenReserve.toLocaleString()} {data && data.symbol}?
                      </p>
                    ),
                    {
                      icon: "⚠️",
                      style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff",
                      },
                    }
                  );
                  setValue("amount", tokenReserve * parseFloat(price));
                } else if (
                  watch("event") === "buy" &&
                  swicht === true &&
                  tokenReserve < watch("amount")
                ) {
                  toast(
                    (t) => (
                      <p className="font-pricedown uppercase tracking-wider flex flex-col justify-end gap-4 items-end w-full">
                        Only X tokens are available{" "}
                        {tokenReserve.toLocaleString()} {data && data.symbol}?
                      </p>
                    ),
                    {
                      icon: "⚠️",
                      style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff",
                      },
                    }
                  );
                  setValue("amount", tokenReserve);
                }
              }}
              className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-0.5"
            >
              1 SOL
            </button>
            <button
              type="button"
              onClick={() => {
                reset({
                  amount: 5,
                  event: watch("event"),
                  totalAmount: watch("totalAmount"),
                  slippage: watch("slippage"),
                });
                if (
                  watch("event") === "buy" &&
                  swicht === false &&
                  tokenReserve < watch("amount") * 10 ** 6
                ) {
                  toast(
                    (t) => (
                      <p className="font-pricedown uppercase tracking-wider flex flex-col justify-end gap-4 items-end w-full">
                        Only X tokens are available{" "}
                        {tokenReserve.toLocaleString()} {data && data.symbol}?
                      </p>
                    ),
                    {
                      icon: "⚠️",
                      style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff",
                      },
                    }
                  );
                  setValue("amount", tokenReserve * parseFloat(price));
                } else if (
                  watch("event") === "buy" &&
                  swicht === true &&
                  tokenReserve < watch("amount")
                ) {
                  toast(
                    (t) => (
                      <p className="font-pricedown uppercase tracking-wider flex flex-col justify-end gap-4 items-end w-full">
                        Only X tokens are available{" "}
                        {tokenReserve.toLocaleString()} {data && data.symbol}?
                      </p>
                    ),
                    {
                      icon: "⚠️",
                      style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff",
                      },
                    }
                  );
                  setValue("amount", tokenReserve);
                }
              }}
              className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-0.5"
            >
              5 SOL
            </button>
            <button
              type="button"
              onClick={() => {
                reset({
                  amount: 10,
                  event: watch("event"),
                  totalAmount: watch("totalAmount"),
                  slippage: watch("slippage"),
                });
                if (
                  watch("event") === "buy" &&
                  swicht === false &&
                  tokenReserve < watch("amount") * 10 ** 6
                ) {
                  toast(
                    (t) => (
                      <p className="font-pricedown uppercase tracking-wider flex flex-col justify-end gap-4 items-end w-full">
                        Only X tokens are available{" "}
                        {tokenReserve.toLocaleString()} {data && data.symbol}?
                      </p>
                    ),
                    {
                      icon: "⚠️",
                      style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff",
                      },
                    }
                  );
                  setValue("amount", tokenReserve * parseFloat(price));
                } else if (
                  watch("event") === "buy" &&
                  swicht === true &&
                  tokenReserve < watch("amount")
                ) {
                  toast(
                    (t) => (
                      <p className="font-pricedown uppercase tracking-wider flex flex-col justify-end gap-4 items-end w-full">
                        Only X tokens are available{" "}
                        {tokenReserve.toLocaleString()} {data && data.symbol}?
                      </p>
                    ),
                    {
                      icon: "⚠️",
                      style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff",
                      },
                    }
                  );
                  setValue("amount", tokenReserve);
                }
              }}
              className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-0.5"
            >
              10 SOL
            </button>
          </div>
        ) : (
          watch("event") === "sell" && (
            <div className="grid grid-cols-5 gap-2 text-center text-sm w-8/12 max-sm:w-full">
              <button
                type="button"
                onClick={() =>
                  reset({
                    amount: 0,
                    event: watch("event"),
                    totalAmount: watch("totalAmount"),
                    slippage: watch("slippage"),
                  })
                }
                className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-0.5"
              >
                Reset
              </button>
              <button
                type="button"
                onClick={() =>
                  reset({
                    amount: (watch("totalAmount") * 0.25).toFixed(
                      6
                    ) as unknown as number,
                    event: watch("event"),
                    totalAmount: watch("totalAmount"),
                    slippage: watch("slippage"),
                  })
                }
                className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-0.5"
              >
                25%
              </button>
              <button
                type="button"
                onClick={() =>
                  reset({
                    amount: (watch("totalAmount") * 0.51).toFixed(
                      6
                    ) as unknown as number,
                    event: watch("event"),
                    totalAmount: watch("totalAmount"),
                    slippage: watch("slippage"),
                  })
                }
                className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-0.5"
              >
                50%
              </button>
              <button
                type="button"
                onClick={() =>
                  reset({
                    amount: (watch("totalAmount") * 0.75).toFixed(
                      6
                    ) as unknown as number,
                    event: watch("event"),
                    totalAmount: watch("totalAmount"),
                    slippage: watch("slippage"),
                  })
                }
                className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-0.5"
              >
                75%
              </button>
              <button
                type="button"
                onClick={() =>
                  reset({
                    amount: watch("totalAmount").toFixed(
                      6
                    ) as unknown as number,
                    event: watch("event"),
                    totalAmount: watch("totalAmount"),
                    slippage: watch("slippage"),
                  })
                }
                className="bg-white outline outline-2 outline-green_custom font-pricedown uppercase tracking-wider hover:outline-4 text-green_custom rounded-lg p-0.5"
              >
                100%
              </button>
            </div>
          )
        )}
        {watch("amount") !== 0 && (
          <p className="font-poetsen">
            {swicht
              ? watch("amount") * parseFloat(price)
              : watch("amount") / parseFloat(price)}{" "}
            {!swicht ? data && data.symbol : "SOL"}
          </p>
        )}
        <Button
          label="Place Trade"
          icon="fa-solid fa-arrow-down-up-across-line"
          type="submit"
          className="w-full"
          loading={message !== ""}
          loadingMesaage={message !== "" && message}
        />
        <Modal
          isOpen={slippage}
          onClose={() => setSlippage(false)}
          className="w-2/1 max-sm:w-8/12"
        >
          <div className="flex flex-col gap-4 max-sm:gap-8 items-center justify-center">
            <h2 className="text-xl font-pricedown uppercase tracking-wider">
              Set max. slippage (%)
            </h2>
            <div className="flex justify-between items-center border-2 border-green_custom  rounded-lg w-full">
              <button
                type="button"
                className="flex justify-center items-center w-full border-r-2 py-2 border-green_custom"
                onClick={() => {
                  const value = Math.max(Number(watch("slippage")) - 1, 1);
                  setValue("slippage", value);
                }}
              >
                <i className="fas fa-minus"></i>
              </button>
              <Input
                name="slippage"
                type="number"
                min={1}
                max={50}
                className="text-xl py-2  font-pricedown text-center outline-transparent"
                register={register}
                errors={errors.slippage}
                required
              />
              <button
                type="button"
                className="flex justify-center items-center w-full py-2 border-l-2 border-green_custom"
                onClick={() => {
                  const value = Math.min(Number(watch("slippage")) + 1, 50);
                  setValue("slippage", value);
                }}
              >
                <i className="fas fa-plus"></i>
              </button>
            </div>
            <p className="font-poetsen text-center">
              This is the maximum amount of slippage you are willing to accept
              when placing trades
            </p>
          </div>
        </Modal>
      </form>
    );
  }
);

export default FormTrading;
