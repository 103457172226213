import React, { memo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Token } from "src/Api/interface";
import { getApiMethod } from "src/Api/token";
import Button from "./Button";
import Input from "./Inputs";
import { motion } from "framer-motion";

interface selectOption {
  name: string;
  value: string;
}

interface ForsearchProps {
  onChange?: (value: Token[]) => void;
}

const FormSearch = memo<ForsearchProps>(({ onChange }) => {
  const api = new getApiMethod();
  const [loading, setLoading] = useState(false);
  const [sortoption] = useState<selectOption[]>([
    { name: "Sort", value: "Sort" },
    { name: "Sort: Creation time", value: "Sort: Creation time" },
    { name: "Sort: Market Cap", value: "Sort: Market Cap" },
    { name: "Sort: MOONSHOT", value: "Sort: MOONSHOT" },
  ]);
  const [ordenoption] = useState<selectOption[]>([
    { name: "ORDER", value: "ORDER" },
    { name: "A-Z", value: "A-Z" },
  ]);
  const [data, setData] = useState<Token[]>([]);
  const { register, setValue, watch } = useForm();
  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = async (search) => {
    setLoading(true);
    const dataTokens = await api.getTokenByname(search);
    onChange(dataTokens);
    setData(dataTokens);
    setLoading(false);
  };

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 1 }}
      id="formSearch"
      ref={formRef}
      className="flex flex-col gap-2 w-full"
    >
      <div className="grid grid-cols-4 max-sm:grid-cols-2 w-full gap-4">
        <div></div>
        <div></div>
        <div className="relative w-full max-sm:order-1 flex items-center justify-center col-span-2">
          <Input
            name="search"
            type="text"
            className="py-1 bg-white outline-0 text-gray_custom max-sm:text-2xl placeholder:uppercase placeholder:text-gray_custom text-4xl border-2"
            placeholder="Search for Token"
            autoComplete="off"
            isText={true}
            register={register}
            onChange={(e) => {
              setValue("search", e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                onSubmit(e.target.value);
              }
            }}
            required
          />
          <Button
            type="button"
            onClick={() => onSubmit(watch("search"))}
            icon={
              loading
                ? "fas fa-spinner text-gray_custom animate-spin"
                : "fa-solid fa-magnifying-glass text-gray_custom"
            }
            className="py-3 absolute right-0 rounded-none "
            color="px-2"
          />
          {data.length !== 0 && (
            <Button
              type="button"
              icon="fas fa-xmark text-red-500 text-xl"
              className="py-3 absolute right-16 rounded-none"
              color="px-2"
              onClick={async () => {
                setLoading(true);
                setData([]);
                onChange(await api.getTokens());
                setValue("search", null);
                setLoading(false);
              }}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-4 max-sm:grid-cols-2 w-full gap-4">
        <div className="flex flex-col w-full gap-4 relative z-20 col-span-2 max-sm:order-2">
          <div>
            <h2 className="font-pricedown text-6xl max-sm:text-4xl text-center w-full">
              MOONSHOT
            </h2>
          </div>
        </div>
        <div className="w-full">
          <select
            name="sort"
            id="sort"
            className="focus:outline py-1 rounded-md bg-white text-center font-pricedown uppercase text-4xl tracking-wider text-gray_custom font-normal w-full border-2 px-6 appearance-none focus:outline-green_custom max-sm:text-3xl"
            onChange={async (e) => {
              setLoading(true);
              switch (e.target.value) {
                case "2":
                  onChange(await api.getTokens());
                  break;
                case "3":
                  onChange(await api.getTokenOrdernByMarkecap());
                  break;

                case "4":
                  onChange(await api.getTokenOrdernByLaunched());
                  break;

                default:
                  onChange(await api.getTokens());
                  break;
              }
              setLoading(false);
            }}
          >
            {sortoption.map((option, index) => (
              <option value={`${index + 1}`} key={index}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <select
            name="order"
            id="order"
            className="focus:outline py-1 rounded-md bg-white text-center font-pricedown uppercase text-4xl text-gray_custom tracking-wider font-normal w-full border-2 px-6 appearance-none focus:outline-green_custom max-sm:text-3xl"
            onChange={async (e) => {
              setLoading(true);
              switch (e.target.value) {
                case "2":
                  onChange(await api.getTokenOrdernByName());
                  break;

                default:
                  onChange(await api.getTokens());
                  break;
              }
              setLoading(false);
            }}
          >
            {ordenoption.map((option, index) => (
              <option value={`${index + 1}`} key={index}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </motion.form>
  );
});

export default FormSearch;
