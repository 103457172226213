import { useWallet } from "@solana/wallet-adapter-react";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { Followers, Token, User, BalanceProps } from "src/Api/interface";
import { getApiMethod } from "src/Api/token";
import Card from "src/Components/Card";
import Loading from "src/Components/Loading";
import Pagination from "src/Components/Pagination";
import { formatNumber } from "src/utils/funtions";
import { motion } from "framer-motion";

interface initialData {
  dataUser: User;
  dataToken: Token[];
  dataFollower: { count: number; results: Followers[] };
  dataFollowing: { count: number; results: Followers[] };
  dataHolder?: BalanceProps[];
}

const Profile = () => {
  const { pubkey } = useParams();
  const [data, setData] = useState<initialData | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<
    "Holder" | "Created" | "Followers" | "Following"
  >("Created");
  const [follow, setFollow] = useState<number>(0);
  const { publicKey } = useWallet();

  const getAsyncData = useCallback(async () => {
    const api = new getApiMethod();
    let data: initialData;
    try {
      let dataUser = await api.getUser(pubkey);

      let [dataToken, dataFollower, dataFollowing] = await Promise.all([
        api.getTokenByWallet(pubkey),
        api.getFollowersByPublick(pubkey),
        api.getFollowersByFollowing(pubkey),
      ]);
      setFollow(dataFollower.count);
      data = { dataUser, dataToken, dataFollower, dataFollowing };
      setData(data);
    } catch (error) {
      console.error(error);
    }
    try {
      const dataHolder = await api.getTokenBalances(pubkey);
      setData({ ...data, dataHolder });
    } catch (error) {
      console.error(error);
    }
  }, [pubkey]);

  useEffect(() => {
    getAsyncData();
  }, [getAsyncData]);

  const Menu: ("Holder" | "Created" | "Followers" | "Following")[] = [
    "Holder",
    "Created",
    "Followers",
    "Following",
  ];

  const renderCard = (item: Token, index: number) => {
    return (
      <Card
        key={index}
        image={item.ipfs_url}
        create={item.wallet_creator}
        makertcap={`${formatNumber(parseInt(item.marketcap))}`}
        replies={item.description}
        name={item.name}
        mint={item.mint}
        symbol={item.symbol}
        classTitle="text-lg"
      />
    );
  };
  const renderHolder = (item: BalanceProps, index: number) => {
    const { quantity } = item;
    return (
      <Link
        key={index}
        to={`/market/${item?.token?.mint}`}
        className="w-full bg-white outline outline-2 hover:outline-green_custom outline-white hover:outline-4 flex justify-start items-center gap-4 rounded-lg font-pricedown tracking-wider p-2"
      >
        <div className="flex justify-start items-start w-full gap-2">
          <img
            src={item?.token?.ipfs_url}
            alt={item?.token?.ipfs_url}
            className="w-20 h-20 rounded-lg"
          />
          <div className="text-xl w-8/12 max-sm:text-base max-sm:w-full">
            <div className="flex gap-4 text-green_custom max-sm:flex-col max-sm:gap-0">
              <h2 className="uppercase max-sm:text-nowrap truncate">
                {item?.token?.name}
              </h2>
              <span className="uppercase">(TICKER: {item?.token?.symbol})</span>
            </div>
            <p className="line-clamp-3 max-sm:line-clamp-2 font-poetsen text-xs">
              {item?.token?.description}
            </p>
          </div>
        </div>
        <div className="text-2xl text-green_custom">
          {formatNumber(Number(quantity))}
        </div>
      </Link>
    );
  };
  const renderFoller = (item: Followers, index: number) => {
    const { followerkey } = item;
    return (
      <Link
        key={index}
        to={`/profile/${followerkey}`}
        className="w-full bg-white outline outline-2 hover:outline-green_custom outline-white hover:outline-4 flex justify-center items-center gap-4 max-sm:rounded-full rounded-lg font-pricedown tracking-wider max-sm:p-0 p-2 overflow-hidden"
      >
        <div className="flex justify-start items-center w-full gap-2">
          <img
            src={item?.followerkey_info?.image}
            alt={item?.followerkey_info?.image}
            className="max-sm:h-10 max-sm:w-10 w-20 h-20 max-sm:rounded-r-full"
          />
          <div className="text-xl max-sm:text-base w-full">
            <div className="flex gap-4 text-green_custom max-sm:flex-col max-sm:gap-0">
              <h2 className="uppercase max-sm:text-nowrap truncate">
                {item?.followerkey_info?.username}
              </h2>
            </div>
          </div>
        </div>
      </Link>
    );
  };
  const renderFollWing = (item: Followers, index: number) => {
    const { pubkey } = item;
    return (
      <Link
        key={index}
        to={`/profile/${pubkey}`}
        className="w-full bg-white outline outline-2 hover:outline-green_custom outline-white hover:outline-4 flex justify-center items-center gap-4 max-sm:rounded-full rounded-lg font-pricedown tracking-wider max-sm:p-0 p-2 overflow-hidden"
      >
        <div className="flex justify-start items-center w-full gap-2">
          <img
            src={item?.pubkey_info?.image}
            alt={item?.pubkey_info?.image}
            className="max-sm:h-10 max-sm:w-10 w-20 h-20 max-sm:rounded-r-full"
          />
          <div className="text-xl max-sm:text-base w-full">
            <div className="flex gap-4 text-green_custom max-sm:flex-col max-sm:gap-0">
              <h2 className="uppercase max-sm:text-nowrap truncate px-2">
                {item?.pubkey_info?.username}
              </h2>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  if (!data) {
    return <Loading isLoading={false} />;
  }
  const { dataFollower, dataHolder, dataToken, dataUser, dataFollowing } = data;
  const { image, username, description, created_at, userRef, referred_by } =
    dataUser;
  const { count: followers, results } = dataFollower;
  const { results: dataFollwing } = dataFollowing;
  return (
    <div>
      <motion.div
        initial={{ opacity: 0, left: 100, position: "relative" }}
        animate={{ opacity: 1, left: 0, position: "relative" }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0 }}
        className="w-full h-full flex justify-center items-center"
      >
        <div className="w-full h-full flex justify-center items-start max-sm:flex-col outline outline-2 outline-green_custom rounded-lg bg-white ">
          <motion.div
            initial={{ opacity: 0, right: 100, position: "relative" }}
            animate={{ opacity: 1, right: 0, position: "relative" }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1 }}
            className="flex flex-col gap-4 justify-start items-start w-4/12 p-4 max-sm:w-full"
          >
            <img
              src={image}
              alt={image}
              className="w-96 h-96 shadow-lg max-sm:mb-4 rounded-xl"
            />
            <div>
              <h2 className="uppercase font-pricedown tracking-wider text-4xl max-sm:text-2xl">
                {username}
              </h2>
              <div className="h-1 w-8/12 bg-white rounded-md" />
            </div>
            <p className="text-xl font-poetsen max-sm:text-base line-clamp-6 text-justify">
              {description}
            </p>
            <div className="font-pricedown uppercase text-6xl flex justify-between w-full gap-4 max-sm:text-4xl">
              <div className="text-xl">
                <h4 className="text-green_custom">User created</h4>
                <p className="font-poetsen">
                  {new Date(created_at).toLocaleDateString()}
                </p>
              </div>
              <div className="flex gap-4">
                <button
                  type="button"
                  className={`fas fa-heart hover:text-green_custom transition-all cursor-pointer ${
                    follow !== followers ||
                    (publicKey &&
                      results.find(
                        (x) => x.followerkey === publicKey.toString()
                      ))
                      ? "text-green_custom"
                      : "text-red-500"
                  }`}
                  onClick={async () => {
                    if (!publicKey)
                      return toast.error("A wallet required", {
                        style: {
                          borderRadius: "10px",
                          background: "#333",
                          color: "#fff",
                        },
                      });
                    const api = new getApiMethod();
                    try {
                      setFollow(followers + 1);
                      await api.createFollowers({
                        pubkey: pubkey,
                        followerkey: publicKey.toString(),
                      });
                      toast.success("You've expressed that you liked it", {
                        style: {
                          borderRadius: "10px",
                          background: "#333",
                          color: "#fff",
                        },
                      });
                      return getAsyncData();
                    } catch (error) {
                      setFollow(followers);
                      console.log(error);
                      toast.error("Failed to like", {
                        style: {
                          borderRadius: "10px",
                          background: "#333",
                          color: "#fff",
                        },
                      });
                    }
                  }}
                  disabled={
                    follow !== followers ||
                    (publicKey &&
                      results.find(
                        (x) => x.followerkey === publicKey.toString()
                      ))
                      ? true
                      : false
                  }
                ></button>
                {followers}
              </div>
            </div>
            {referred_by && userRef && (
              <div className="flex flex-col justify-center items-start w-full">
                <h2 className="uppercase text-xl font-pricedown tracking-wider text-green_custom">
                  Refer
                </h2>
                <Link
                  to={`/profile/${userRef.pubkey}`}
                  className="flex justify-start items-center outline outline-2 outline-green_custom rounded-full overflow-hidden"
                >
                  <img
                    className="w-10 h-10"
                    src={userRef.image}
                    alt={userRef.image}
                  />
                  <h2 className="uppercase font-pricedown tracking-wider px-6">
                    {userRef.username}
                  </h2>
                </Link>
              </div>
            )}
          </motion.div>
          <div className="h-full min-h-96 w-1 rounded-full bg-white max-sm:min-h-0 max-sm:w-full lg:mt-20 max-sm:h-1 lg:mr-4" />
          <div className="p-4 w-full h-full flex flex-col gap-4 justify-start items-center">
            <div className="flex justify-center items-center outline outline-2 rounded-lg divide-x-2 outline-green_custom divide-green_custom">
              {Menu.filter(
                (_, index) =>
                  !(
                    index === 0 &&
                    pubkey !== (publicKey && publicKey.toString())
                  )
              ).map((menu, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    className={`${index === 0 && "rounded-l-md"} ${
                      Menu.length - 1 === index && "rounded-r-md"
                    } ${
                      selectedMenu === menu && "bg-green_custom text-white"
                    } text-center py-2 px-6 uppercase font-pricedown text-2xl max-sm:text-xs max-sm:px-2 hover:bg-green_custom transition-all hover:text-white`}
                    onClick={() => setSelectedMenu(menu)}
                  >
                    {index < Menu.length - 3 && (
                      <span className="max-sm:hidden">Coins </span>
                    )}
                    {menu}
                  </button>
                );
              })}
            </div>
            {selectedMenu === "Holder" &&
              dataHolder &&
              dataHolder.length === 0 && (
                <div className="w-full p-4 outline outline-2 outline-green_custom rounded-lg flex justify-center items-center text-2xl uppercase font-pricedown tracking-wider">
                  Holding nothing
                </div>
              )}
            {((selectedMenu === "Created" &&
              dataToken &&
              dataToken.length === 0) ||
              (selectedMenu === "Followers" &&
                results &&
                results.length === 0) ||
              (selectedMenu === "Following" &&
                dataFollwing &&
                dataFollwing.length === 0)) && (
              <div className="w-full p-4 outline outline-2 outline-green_custom rounded-lg flex justify-center items-center text-2xl uppercase font-pricedown tracking-wider">
                There is nothing here yet
              </div>
            )}

            <Pagination
              itemsPerPage={12}
              data={
                selectedMenu === "Created"
                  ? dataToken
                  : selectedMenu === "Holder"
                  ? dataHolder.filter((x) => Number(x.quantity) > 0)
                  : selectedMenu === "Followers"
                  ? results.filter(
                      (x) =>
                        x.followerkey_info !== null &&
                        x.pubkey !== x.followerkey
                    )
                  : selectedMenu === "Following" &&
                    dataFollwing.filter(
                      (x) =>
                        x.pubkey_info !== null && x.followerkey !== x.pubkey
                    )
              }
              colum={selectedMenu === "Holder" ? 2 : 3}
              columSm={
                selectedMenu === "Followers" || selectedMenu === "Following"
                  ? 2
                  : 1
              }
              renderItem={
                selectedMenu === "Created"
                  ? renderCard
                  : selectedMenu === "Holder"
                  ? renderHolder
                  : selectedMenu === "Followers"
                  ? renderFoller
                  : selectedMenu === "Following" && renderFollWing
              }
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Profile;
