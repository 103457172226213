import type { WalletName } from "@solana/wallet-adapter-base";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletIcon } from "@solana/wallet-adapter-react-ui";
import React from "react";
import { User } from "src/Api/interface";
import { Button } from "./ButtonWallet";

type Props = React.ComponentProps<typeof Button> & {
  walletIcon?: string;
  walletName?: WalletName;
  dataUser: User | null;
  isMobile?: boolean;
  id: string;
};

export function BaseWalletConnectionButton({
  walletIcon,
  walletName,
  dataUser,
  isMobile,
  id,
  ...props
}: Props) {
  const { publicKey } = useWallet();
  return (
    <Button
      {...props}
      id={id}
      isMobile={isMobile}
      className={`${
        !isMobile &&
        `wallet-adapter-button-trigger px-6 py-1 text-2xl max-sm:py-1 max-sm:text-lg ${
          publicKey && "pr-14 max-sm:pr-10 max-sm:pl-2"
        }  group`
      } `}
      startIcon={
        walletIcon && walletName ? (
          <WalletIcon
            wallet={{ adapter: { icon: walletIcon, name: walletName } }}
          />
        ) : (
          <i className="fa-solid fa-plug px-2.5 text-green_custom group-hover:text-white"></i>
        )
      }
      endIcon={
        !isMobile && publicKey ? (
          <img
            src={dataUser && dataUser.image}
            className="h-12 max-sm:h-10"
            alt="logo"
          />
        ) : undefined
      }
    />
  );
}
