import React from "react";
import { motion } from "framer-motion";

const Loading = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <motion.div
      initial={{ opacity: 0, right: 100, position: "fixed" }}
      animate={{ opacity: 1, right: 0, position: "fixed" }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0 }}
      className={`${
        isLoading && "scale-0 ease-out duration-300 delay-150"
      } transition-all flex justify-center items-center w-full h-screen z-50 bg-white/70 top-0 left-0`}
    >
      {/* <img
        src="/images/loading.jpg"
        alt="bonkmon-face-right.png"
        className="w-screen h-screen fixed z-50 inset-0"
      /> */}
      <div className="font-pricedown bottom-3 right-5 w-full flex justify-end items-center gap-4 z-50 fixed uppercase text-8xl max-sm:text-4xl">
        <i className="fas fa-spinner animate-spin text-gray_custom"></i>
        <h2 className="text-gray_custom">Loading...</h2>
      </div>

      <div className="containerNave">
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
      </div>
    </motion.div>
  );
};

export default Loading;
