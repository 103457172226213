import React, { useCallback, useEffect, useState } from "react";
import Button from "../Components/Button";
import Pagination from "../Components/Pagination";
import { Link, useParams } from "react-router-dom";
import { getApiMethod } from "src/Api/token";
import { Comments, MaketCapPrice, Token, TraderData } from "src/Api/interface";
import { useCounterProgram } from "src/utils/setup";
import * as anchor from "@coral-xyz/anchor";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { addHTTPS, calMarketPrice, formatNumber } from "src/utils/funtions";
import ModalComet from "src/Components/ModalComet";
import FormTrading from "src/Components/FormTrading";
import { CardComment } from "src/Components/CardCommnet";
import { TVChartContainer } from "src/Components/TradingChart";
import Loading from "src/Components/Loading";
import TabMenu from "src/Components/TabMenu.mobile";
import { motion } from "framer-motion";

interface Boarding {
  poolTokenReserves: anchor.BN;
  poolSolReserves: anchor.BN;
  tokenReserves: anchor.BN;
  solReserves: anchor.BN;
  totalSupply: anchor.BN;
  complete: boolean;
  bump: number;
}
interface DataProps {
  dataToken: Token;
  supply: Boarding;
  bordinPorcent: string;
  holder: {
    address: string;
    amount: string;
    decimals: number;
    uiAmount: number | null;
    uiAmountString?: string;
    hypelink: string;
  }[];
  dataTransaction: TraderData[];
  dataComment: Comments[];
  price: MaketCapPrice;
  mention?: string | null;
  marketBondig: string;
}

const Market = () => {
  const { mint } = useParams();

  const [data, setData] = useState<DataProps | null>(null);
  const [transaction, setTransaction] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { getBordingCurve } = useCounterProgram();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({ name: "Holder", active: true });
  const {
    bordinPorcent,
    dataComment,
    dataToken,
    dataTransaction,
    holder,
    price,
    supply,
    mention,
  } = data || {};

  const getAsyncData = useCallback(async () => {
    let dataToken: Token;
    let tx: TraderData[];
    let commnet: Comments[];
    let dataPrice: MaketCapPrice;

    const api = new getApiMethod();
    try {
      dataToken = await api.getTokensById(mint);
      tx = await api.getTraderData(mint);

      [commnet, dataPrice] = await Promise.all([
        api.getCommentsBymint(mint),
        api.getMarkertPricebyId(mint),
      ]);

      let [market, bordingData] = await Promise.all([
        api.getMarkertInfoById(dataToken.mint),
        getBordingCurve(
          dataToken.bonding_curve,
          mint,
          dataToken.wallet_creator
        ),
      ]);

      const totalsuplly =
        market?.initial_token_reserve ||
        new anchor.BN(bordingData.bordin.tokenReserves).toNumber() / 10 ** 6;
      const totalReserve = parseFloat(
        (
          new anchor.BN(bordingData.bordin.tokenReserves).toNumber() /
          10 ** 6
        ).toFixed(0)
      );
      const porcentaje = ((totalsuplly - totalReserve) / totalsuplly) * 100;

      const totalM = Number(
        calMarketPrice(
          new anchor.BN(bordingData.bordin.totalSupply).toNumber() / 10 ** 6,
          market.sol_price,
          market.initial_virtual_sol_reserves
        )
      ).toFixed(2);

      const dataData: DataProps = {
        dataToken,
        holder: bordingData.dataHolder,
        supply: bordingData.bordin,
        bordinPorcent: `${porcentaje.toFixed(2)}%`,
        dataTransaction: tx,
        dataComment: [...commnet].reverse(),
        price: dataPrice,
        marketBondig: totalM,
      };
      setData(dataData);
      setLoading(true);
      if (commnet.length === 0) {
        setTransaction(true);
      }
      document.title = dataToken.name;
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", dataToken.description);
      }
      const favicon =
        document.querySelector<HTMLLinkElement>('link[rel="icon"]');
      if (favicon) {
        favicon.href = dataToken.ipfs_url;
      }
    } catch (error) {
      console.error(error);
      setLoading(true);
    }
  }, [getBordingCurve, mint]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const fetchData = async () => {
      await getAsyncData();
      intervalId = setInterval(() => {
        getAsyncData();
      }, 30000);
    };

    fetchData();

    return () => clearInterval(intervalId);
  }, [getAsyncData]);

  const getRandomColor = () => {
    const colors = [
      "bg-blue-500",
      "bg-amber-600",
      "bg-pink-500",
      "bg-green_custom text-white",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const renderCard = (row: Comments, index: number) => (
    <CardComment
      key={index}
      row={row}
      syncData={getAsyncData}
      onClick={() => {
        setIsOpen(true);
        setData({ ...data, mention: row.id.slice(0, 8) });
      }}
      userColor={getRandomColor()}
    />
  );

  const renderTransctionCard = (row: TraderData, index: number) => {
    if (!row || !row.user) {
      return null;
    }

    let colorClass = getRandomColor();

    return (
      <div
        key={index}
        className={`bg-white outline outline-2 outline-green_custom shadow-md w-full h-full flex flex-row gap-1 p-2 rounded-lg justify-center items-center font-pricedown`}
      >
        <Link
          to={`/profile/${row.user}`}
          className="flex justify-center items-center w-full"
        >
          <span
            className={`font-normal px-2 text-white truncate bg-green_c rounded-full hover:bg-green_custom transition-all ${colorClass}`}
          >
            {row.user.slice(0, 10)}
          </span>
        </Link>
        <p
          className={`w-full text-center capitalize font-medium ${
            row.order_type === "buy"
              ? "text-green-600"
              : row.order_type === "sell"
              ? "text-red-600"
              : "text-blue_custom"
          }`}
        >
          {row.order_type}
        </p>
        <p className="w-full text-center">{Number(row.price).toFixed(9)}</p>
        <p className="w-full text-center">{formatNumber(row.quantity)}</p>
        {row.time && (
          <p className="w-full text-center max-sm:text-xs">
            {new Date(row.time * 1000).toLocaleDateString("es-ES", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-start items-start w-full gap-4">
      <Loading isLoading={loading} />
      <TabMenu
        menu={[
          { class: "", icon: "fas fa-users", name: "Thread" },
          { class: "", icon: "fas fa-user-group", name: "Trades" },
          { class: "", icon: "fas fa-fire-flame-curved", name: "Holder" },
          { class: "", icon: "fas fa-chart-simple", name: "Chart" },
        ]}
        buttonCenter={{
          class: "",
          icon: "fas fa-arrow-right-arrow-left",
          name: "trading",
          onClick: () => {
            if (supply && supply.complete && dataToken && dataToken.launched) {
              return window.open(
                `https://dexscreener.com/solana/${dataToken.raydium_pair}`,
                "_blank"
              );
            }
          },
        }}
        onClick={(value) => {
          if (value.name === "Thread") {
            setTransaction(false);
          } else if (value.name === "Trades") {
            setTransaction(true);
          } else if (
            value.name === "trading" &&
            supply &&
            supply.complete &&
            dataToken &&
            dataToken.launched
          ) {
            return;
          }
          setPage(value);
        }}
      />
      <motion.div
        initial={{ opacity: 0, right: 100, position: "relative" }}
        animate={{ opacity: 1, right: 0, position: "relative" }}
        exit={{ opacity: 0 }}
        transition={{ delay: 1 }}
        className="flex justify-between items-center w-full max-sm:flex-col max-sm:gap-4"
      >
        <div className="text-base max-sm:text-sm font-normal flex justify-between items-center max-sm:flex-col gap-4">
          <div className="flex justify-between items-center gap-4 font-pricedown uppercase">
            <p className="text-green_custom">{dataToken && dataToken.name}</p>
            <p className="text-green_custom">
              Ticker: {dataToken && dataToken.symbol}
            </p>
            <p>
              Market cap: $
              {dataToken ? parseFloat(dataToken.marketcap).toLocaleString() : 0}
            </p>
            {/* <p>Virtual liquidity: $12,796</p> */}
          </div>
          <div>
            <span className="text-green_custom uppercase font-pricedown">
              Created by
            </span>{" "}
            <Link
              to={`/profile/${dataToken && dataToken.wallet_creator}`}
              className="font-normal outline hover:outline-1 hov rounded-sm px-2 py-0.5 bg-green_custom text-white hover:bg-white hover:text-green_custom font-pricedown tracking-wider transition-all"
            >
              {dataToken && dataToken.wallet_creator.slice(0, 10)}
            </Link>
          </div>
        </div>
        <div className="font-pricedown tracking-wider uppercase text-xl max-sm:text-sm">
          <p>
            Price:{" "}
            <span className="bg-green_ctext-green_custom rounded-sm px-2 py-1">
              ${data && price.price}
            </span>
          </p>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, left: 100, position: "relative" }}
        animate={{ opacity: 1, left: 0, position: "relative" }}
        exit={{ opacity: 0 }}
        transition={{ delay: 1.5 }}
        className="flex w-full max-sm:h-full max-sm:items-center gap-4 max-sm:flex-col"
      >
        <div
          className={`w-full h-full flex flex-col gap-4 ${
            page.name === "Chart" && page.active
              ? ""
              : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0"
          }`}
        >
          <div className="outline outline-2 outline-green_ctext-green_custom rounded-lg shadow-md w-full h-full lg:min-h-96 max-sm:fixed max-sm:min-h-screen max-sm:outline-0 max-sm:left-0 max-sm:top-0 max-sm:z-30">
            {supply && dataToken.launched && supply.complete ? (
              <iframe
                title={dataToken && dataToken.name}
                src={`https://dexscreener.com/solana/${
                  dataToken && dataToken.raydium_pair
                }?embed=1&theme=dark&trades=0&info=0`}
                className="w-full chart"
                frameBorder="0"
              ></iframe>
            ) : (
              <TVChartContainer mint={mint} price={price && price.price} />
            )}
          </div>
        </div>

        <div
          className={`w-6/12 flex flex-col gap-4 max-sm:w-full ${
            supply && supply.complete
              ? "justify-start items-center"
              : "justify-start items-start"
          }`}
        >
          {supply && supply.complete ? (
            dataToken && dataToken.launched ? (
              <a
                href={`https://dexscreener.com/solana/${dataToken.raydium_pair}`}
                target="_blank"
                rel="noreferrer"
                className={`${
                  page.name === "trading" && page.active
                    ? ""
                    : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0"
                } w-full`}
              >
                <Button
                  label={
                    <p>
                      TRADING IN <br className="lg:hidden md:hidden" />{" "}
                      DEXSCREENER
                    </p>
                  }
                  color="text-4xl max-sm:text-2xl"
                  className="w-full text-wrap h-full font-pricedown outline outline-2 outline-green_ctext-green_custom hover:outline-4 rounded-lg tracking-wider"
                />
              </a>
            ) : (
              <h2 className="uppercase font-pricedown text-lg w-full max-sm:text-center max-sm:text-4xl">
                The BONDING CURVE is complete. Pulling data from dexscreener,
                this may take a few minutes...
              </h2>
            )
          ) : (
            <FormTrading
              data={dataToken}
              getAsyncData={getAsyncData}
              price={price && price.price}
              mint={mint}
              className={`${
                page.name === "trading" && page.active
                  ? ""
                  : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0"
              }`}
              tokenReserve={
                supply
                  ? new anchor.BN(supply.tokenReserves).toNumber() / 10 ** 6
                  : 0
              }
            />
          )}

          {dataToken && (
            <div
              className={`${
                page.name === "Holder" && page.active
                  ? ""
                  : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0"
              } grid grid-cols-3 w-full place-items-center font-pricedown uppercase`}
            >
              {dataToken.twitter && dataToken.twitter !== "" && (
                <a
                  href={addHTTPS(dataToken.twitter)}
                  target={"_blank"}
                  className="bg-neutral-800 px-4 py-1.5 rounded-full hover:text-green_custom hover:outline hover:outline-4 hover:outline-green_custom text-green_custom transition-all"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-x-twitter"></i> Twitter
                </a>
              )}
              {dataToken.telegram && dataToken.telegram !== "" && (
                <a
                  href={addHTTPS(dataToken.telegram)}
                  className="bg-sky-500 px-4 py-1.5 rounded-full hover:text-green_custom hover:outline hover:outline-4 hover:outline-green_custom text-green_custom hover:bg-black/90 transition-all"
                  rel="noreferrer"
                >
                  <i className="fa-solid fa-paper-plane"></i> Telegram
                </a>
              )}
              {dataToken.website && dataToken.website !== "" && (
                <a
                  href={addHTTPS(dataToken.website)}
                  className="outline outline-2 outline-green_ctext-green_custom px-4 py-1.5 rounded-full hover:text-green_custom hover:outline hover:outline-4 transition-all hover:outline-green_ctext-green_custom"
                  rel="noreferrer"
                >
                  <i className="fa-regular fa-window-restore"></i> Website
                </a>
              )}
            </div>
          )}
          <div
            className={`${
              page.name === "Holder" && page.active
                ? ""
                : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0"
            } flex w-full gap-4 max-sm:flex-col`}
          >
            <img
              src={(dataToken && dataToken.ipfs_url) || "/images/example3.png"}
              className="w-36 h-36 max-sm:w-screen rounded-lg shadow-lg"
              alt={(dataToken && dataToken.ipfs_url) || "/images/example3.png"}
            />
            <div className="w-9/12 max-sm:w-full relative">
              <p className="text-base text-justify font-medium font-pricedown uppercase tracking-wider text-green_custom">
                {dataToken && dataToken.name}{" "}
                <span className="text-gray_custom">
                  (ticker: {dataToken && dataToken.symbol})
                </span>
              </p>
              <p className="text-base text-justify font-poetsen">
                {dataToken && dataToken.description}
              </p>
              <p className="font-pricedown truncate group">
                CA:{" "}
                <a
                  href={`https://solscan.io/token/${mint}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue_custom underline hover:text-green_custom"
                >
                  {mint}
                </a>
                <span className="bg-white uppercase scale-0 transition-all group-hover:scale-100 font-poetsen tracking-wider p-2 rounded-lg absolute bottom-5 left-10 z-50">
                  view in Solscan{" "}
                </span>
              </p>
            </div>
          </div>
          <div
            className={`${
              page.name === "Holder" && page.active
                ? ""
                : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0"
            } w-full flex flex-col gap-2 font-pricedown tracking-wider uppercase`}
          >
            <p className="text-sm font-normal text-green_custom">
              bonding curve progress:{" "}
              <span className="text-gray_custom">
                {bordinPorcent === "100.00%" ? "Complete" : bordinPorcent}
              </span>
            </p>
            <div className="container-bar bg-white rounded-full relative shadow-md">
              <div
                className="bar"
                style={{ width: bordinPorcent ? bordinPorcent : "" }}
              ></div>
            </div>
          </div>
          <div
            className={`${
              page.name === "Holder" && page.active
                ? ""
                : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0"
            } w-full flex flex-col justify-start items-start gap-4 max-sm:w-full text-justify font-poetsen`}
          >
            <p>
              When the market cap reaches{" "}
              <span className="text-green_custom">
                ${data && data.marketBondig}
              </span>{" "}
              all the liquidity from the bonding curve will be deposited into
              Raydium and burned. progression increases as the price goes up.
            </p>
            <p>
              There are{" "}
              <span className="text-green_custom">
                {supply &&
                  new anchor.BN(supply.tokenReserves).toNumber() / 10 ** 6}
              </span>{" "}
              tokens still available for sale in the bonding curve and there is{" "}
              <span className="text-green_custom">
                {supply &&
                  (
                    new anchor.BN(supply.solReserves).toNumber() /
                    LAMPORTS_PER_SOL
                  ).toLocaleString("en-US")}
              </span>{" "}
              SOL in the bonding curve.
            </p>
            {supply && supply.complete && (
              <p className="text-green_custom font-semibold text-center text-2xl">
                Crowned Top Inmate on{" "}
                <span className="text-gray_custom">
                  {dataToken && dataToken?.launch_date
                    ? new Date(dataToken.launch_date).toLocaleString()
                    : new Date().toLocaleString()}{" "}
                </span>
              </p>
            )}
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, bottom: -100, position: "relative" }}
        animate={{ opacity: 1, bottom: 0, position: "relative" }}
        exit={{ opacity: 0 }}
        transition={{ delay: 1 }}
        className="flex w-full gap-4 max-sm:flex-col-reverse"
      >
        <div
          className={`${
            (page.name === "Thread" || page.name === "Trades") && page.active
              ? ""
              : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0"
          } w-full`}
        >
          <div className="flex justify-between items-start gap-4 mb-2">
            <div className="flex justify-center items-center font-medium text-lg bg-white outline outline-green_custom rounded-lg font-pricedown tracking-wider hover:outline-2 max-sm:text-sm max-sm:hidden">
              <button
                className={`${
                  !transaction
                    ? "bg-green_custom text-white rounded-l-md font-semibold"
                    : ""
                } p-2 px-4 text-center w-full uppercase`}
                type="button"
                onClick={() => setTransaction(false)}
              >
                Thread
              </button>
              <div className="h-5 w-0.5 bg-white" />
              <button
                className={`${
                  transaction
                    ? "bg-green_custom text-white rounded-r-md font-semibold"
                    : ""
                } p-2 px-4 text-center w-full uppercase`}
                type="button"
                onClick={() => setTransaction(true)}
              >
                Trades
              </button>
            </div>
            <div className="lg:hidden md::hidden"></div>
            <div>
              <Button
                label={"Post a Reply"}
                className={`px-11 text-lg max-sm:text-sm ${
                  page.name === "Thread" && page.active
                    ? ""
                    : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0 hover:bg-green_custom hover:text-white"
                }`}
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>
          {dataTransaction && dataTransaction.length > 0 && transaction && (
            <div className="bg-white outline outline-2 outline-green_ctext-green_custom font-pricedown tracking-wider uppercase shadow-md w-full flex gap-1 p-2 rounded-lg mb-2 text-center divide-x-2">
              <p className="w-full text-center font-medium">Account</p>
              <p className="w-full text-center font-medium">Type</p>
              <p className="w-full text-center font-medium">SOL</p>
              <p className="w-full text-center font-medium">
                {dataToken && dataToken.symbol}
              </p>
              <p className="w-full text-center font-medium">Date</p>
            </div>
          )}
          {(dataComment && dataComment.length !== 0) ||
          (dataTransaction && dataTransaction.length !== 0 && transaction) ? (
            <Pagination
              itemsPerPage={12}
              data={
                dataComment && dataTransaction && !transaction
                  ? dataComment && dataComment
                  : [...dataTransaction]
              }
              colum={1}
              renderItem={
                dataComment && dataTransaction && !transaction
                  ? renderCard
                  : renderTransctionCard
              }
            />
          ) : (
            <div className="bg-white outline outline-2 outline-green_ctext-green_custom font-pricedown tracking-wider uppercase shadow-md w-full flex gap-1 p-2 rounded-lg mb-2 text-center justify-center">
              <h2>There are no comments or transactions at the moment.</h2>
            </div>
          )}
        </div>

        <div
          className={`${
            page.name === "Holder" && page.active
              ? ""
              : "max-sm:scale-0 transition-all max-sm:absolute max-sm:-top-96 max-sm:h-0"
          } w-6/12 max-sm:w-full flex flex-col gap-4 px-2 font-medium text-lg font-poetsen tracking-wider max-sm:text-sm`}
        >
          <p className="font-medium">Holder distribution</p>
          <div className="flex flex-col items-center gap-1 w-full">
            {holder &&
              holder.map((row, index) => {
                const totalQuantity = holder.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.uiAmount,
                  0
                );
                const percentage = (
                  (row.uiAmount / totalQuantity) *
                  100
                ).toFixed(2);

                if (percentage === "0.00") {
                  return null;
                }

                return (
                  <div
                    key={index}
                    className="flex flex-row justify-between items-center gap-4 w-full"
                  >
                    <a
                      href={row.hypelink}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-green_custom"
                    >
                      {index + 1}. {row.address}
                    </a>
                    <p>{percentage}%</p>
                  </div>
                );
              })}
          </div>
        </div>
      </motion.div>
      <ModalComet
        isOpen={isOpen}
        mention={mention}
        onClose={() => {
          setIsOpen(false);
          getAsyncData();
          setData({ ...data, mention: null });
        }}
        mint={mint}
      />
    </div>
  );
};

export default Market;
