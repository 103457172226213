import React from "react";
import { motion } from "framer-motion";

const Footer = ({ onClick }: { onClick: () => void }) => {
  const Menu = [
    {
      name: "bolita.svg",
      hash: " https://www.dextools.io/app/en/solana/pair-explorer/4j44ezVK3rNWQnaehX6uubaNK9Rm4fwFLMp4VNKSzLFX?t=1711977556708",
    },
    {
      name: "aguila.svg",
      hash: "https://dexscreener.com/solana/4j44ezvk3rnwqnaehx6uubank9rm4fwflmp4vnkszlfx",
    },
    { name: "telegram.svg", hash: "https://t.me/sombonkmonverify" },
    { name: "twitter.svg", hash: "https://twitter.com/sombonkmonfraud" },
  ];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 1 }}
    >
      <div
        className={`bg-zinc-100 border-t w-full h-full py-4 flex justify-end gap-4 items-center px-6 relative z-0 max-sm:mb-20`}
      >
        <button
          type="button"
          className="font-pricedown uppercase text-xl text-black"
          onClick={() => onClick()}
        >
          How to use
        </button>
        {Menu.map((item, index) => (
          <a
            key={index}
            href={item.hash}
            target="_blank"
            className="bg-white rounded-full"
            rel="noreferrer"
          >
            <img src={`/images/icons/${item.name}`} alt={item.name} />
          </a>
        ))}
      </div>
    </motion.div>
  );
};

export default Footer;
