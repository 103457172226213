import React, { memo } from "react";
import { motion } from "framer-motion";

interface InputProps {
  label?: string;
  name: string;
  register: any;
  errors?: any;
  textarea?: boolean;
  required?: boolean;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  numericalOnly?: boolean;
  isText?: boolean;
  [key: string]: any;
}

const Input = memo<InputProps>(
  ({
    label,
    name,
    register,
    errors,
    textarea,
    required,
    className,
    onChange,
    numericalOnly,
    isText,
    ...rest
  }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;
      if (numericalOnly) {
        inputValue = inputValue.replace(/[^0-9.]/g, "");
        const dotIndex = inputValue.indexOf(".");
        if (dotIndex !== -1) {
          inputValue =
            inputValue.slice(0, dotIndex + 1) +
            inputValue.slice(dotIndex).replace(/\./g, "");
        }
      }
      if (onChange) {
        onChange({ ...event, target: { ...event.target, value: inputValue } });
      }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.3 }}
        className="flex flex-col gap-2 w-full"
      >
        {label && (
          <label
            className="font-normal text-md text-green_custom text-md tracking-wider font-pricedown uppercase"
            htmlFor={name}
          >
            {label}
          </label>
        )}
        {textarea ? (
          <textarea
            {...register(
              name,
              required && {
                required:
                  name === "content"
                    ? "You can’t comment without a text my brother in christ"
                    : `a ${name} is required`,
              }
            )}
            {...rest}
            className={`${
              errors && "outline outline-green_custom text-green_custom"
            } p-2 text-xl rounded-lg focus:outline-4 font-pricedown bg-transparent outline outline-green_custom text-green_custom ${className}`}
            onChange={handleChange}
          />
        ) : (
          <input
            {...register(
              name,
              required
                ? { required: `a ${name} is required` }
                : { required: false }
            )}
            {...rest}
            className={`${
              errors &&
              errors &&
              "outline outline-green_custom text-green_custom"
            } ${className} ${
              !isText && "text-xl"
            }  p-2 rounded-lg font-pricedown focus:outline-4 bg-transparent outline outline-green_custom text-green_custom`}
            onChange={rest.type === "file" ? handleFileChange : handleChange}
          />
        )}
        {errors && (
          <span className="text-green_custom font-normal text-sm font-pricedown">
            <i className="fa-solid fa-triangle-exclamation"></i>{" "}
            {errors.message}
          </span>
        )}
      </motion.div>
    );
  }
);

export default Input;
