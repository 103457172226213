// Here we export some useful types and functions for interacting with the Anchor program.
import { AnchorProvider, Program } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import LaunchpadIDL from "../launchpad.json";
import { Launchpad } from "./launchpad";

// The programId is imported from the program IDL.
export const LAUNCHPAD_PROGRAM_ID = new PublicKey(LaunchpadIDL.address);

// This is a helper function to get the Counter Anchor program.
export function getLaunchProgram(provider: AnchorProvider) {
  return new Program(LaunchpadIDL as Launchpad, provider);
}

// This is a helper function to get the program ID for the Counter program depending on the cluster.
export function getLaunchProgramId() {
  return LAUNCHPAD_PROGRAM_ID;
}
