import React, { useEffect, useState, FunctionComponent } from "react";
import { motion } from "framer-motion";

interface PaginationProps {
  itemsPerPage: number;
  data: any[];
  renderItem: (item: any, index: number) => JSX.Element;
  colum?: number;
  columSm?: number;
}

const Pagination: FunctionComponent<PaginationProps> = ({
  itemsPerPage,
  data,
  renderItem,
  colum,
  columSm,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [colums, setColums] = useState(3);
  const maxButtonsToShow = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [currentItems, setCurrentItems] = useState<any[]>([]);

  useEffect(() => {
    setCurrentItems([]);
    setTimeout(() => {
      const newItems = data
        ? data.slice(indexOfFirstItem, indexOfLastItem)
        : [];
      setCurrentItems(newItems);
    }, 0);
  }, [currentPage, data, indexOfFirstItem, indexOfLastItem]);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    if (colum) {
      setColums(colum);
    }
  }, [colums, colum]);

  const renderPageButtons = () => {
    const buttons = [];
    let startingPage = Math.max(
      currentPage - Math.floor(maxButtonsToShow / 2),
      1
    );
    let endingPage = Math.min(startingPage + maxButtonsToShow - 1, totalPages);
    if (endingPage - startingPage + 1 < maxButtonsToShow) {
      startingPage = Math.max(1, endingPage - maxButtonsToShow + 1);
    }
    for (let i = startingPage; i <= endingPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 ${
            currentPage === i
              ? "bg-green_custom text-white rounded-sm font-semibold"
              : "bg-transparent"
          }`}
        >
          {i}
        </button>
      );
    }
    if (startingPage > 1) {
      buttons.unshift(
        <span key="left-ellipsis" className="mx-1">
          ...
        </span>
      );
    }
    if (endingPage < totalPages) {
      buttons.push(
        <span key="right-ellipsis" className="mx-1">
          ...
        </span>
      );
    }
    return buttons;
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, left: 100, position: "relative" }}
        animate={{ opacity: 1, left: 0, position: "relative" }}
        exit={{ opacity: 0 }}
        transition={{ delay: 1 }}
        className={`grid ${
          colum
            ? `grid-cols-${colums}`
            : "lg:grid-cols-2 md:grid-cols-2 4xl:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-4"
        } ${
          columSm ? `max-sm:grid-cols-${columSm}` : "max-sm:grid-cols-1"
        } gap-4`}
      >
        {currentItems.map(renderItem)}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, bottom: -100, position: "relative" }}
        animate={{ opacity: 1, bottom: 0, position: "relative" }}
        exit={{ opacity: 0, bottom: -100, position: "relative" }}
        transition={{ delay: 1 }}
        className="w-full flex justify-center items-center h-full"
      >
        <div className="flex justify-center items-center mt-4 bg-transparent font-normal border text-gray_custom rounded-full p-2 shadow-md">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="mx-1 px-3 py-1 bg-transparent"
          >
            <i className="fas fa-angle-left"></i>
          </button>
          {renderPageButtons()}
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="mx-1 px-3 py-1 bg-transparent"
          >
            <i className="fas fa-angle-right"></i>
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default Pagination;
