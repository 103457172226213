import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useAnchorProvider } from "../Components/Props";
import { getLaunchProgram, getLaunchProgramId } from "./launchpad-export";
import * as anchor from "@coral-xyz/anchor";
import { getApiMethod } from "src/Api/token";
import { Token } from "src/Api/interface";
import shortUUID from "short-uuid";
import { useCallback } from "react";
import { getAssociatedTokenAddressSync } from "./funtions";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

export function useCounterProgram() {
  const provider = useAnchorProvider();
  const programId = getLaunchProgramId();
  const program = getLaunchProgram(provider);
  const { publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();

  const api = new getApiMethod();

  const createToken = async ({
    name,
    symbol,
    uri,
    description,
    telegram,
    website,
    twitter,
    solamount,
  }: {
    name: string;
    symbol: string;
    uri: string;
    description: string;
    telegram: string;
    website: string;
    twitter: string;
    solamount: number;
  }) => {
    if (!publicKey) return;
    const TOKEN_ID = shortUUID.generate().substring(0, 11);
    const [MINT_PDA] = anchor.web3.PublicKey.findProgramAddressSync(
      [Buffer.from("mint"), Buffer.from(TOKEN_ID), Buffer.from(name)],
      program.programId
    );

    const METADATA_PROGRAM_ID = new anchor.web3.PublicKey(
      "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
    );
    const [METADATA_PDA] = anchor.web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from("metadata"),
        METADATA_PROGRAM_ID.toBuffer(),
        MINT_PDA.toBuffer(),
      ],
      METADATA_PROGRAM_ID
    );

    const [BONDING_CURVE_PDA] = anchor.web3.PublicKey.findProgramAddressSync(
      [Buffer.from("bonding_curve"), MINT_PDA.toBuffer()],
      program.programId
    );

    const BONDING_CURVE_ATA = anchor.utils.token.associatedAddress({
      mint: MINT_PDA,
      owner: BONDING_CURVE_PDA,
    });

    const [CONFIG_PDA] = anchor.web3.PublicKey.findProgramAddressSync(
      [Buffer.from("config")],
      program.programId
    );
    const SOL_AMOUNT =
      solamount !== 70 ? new anchor.BN(solamount * LAMPORTS_PER_SOL) : null;
    try {
      const token = await program.methods
        .createToken(TOKEN_ID, name, symbol, uri, SOL_AMOUNT)
        .accounts({
          config: CONFIG_PDA,
          metadata: METADATA_PDA,
          bondingCurveAta: BONDING_CURVE_ATA,
          user: publicKey,
          mint: MINT_PDA,
          bondingCurve: BONDING_CURVE_PDA,
        })
        .instruction();
      const recentBlock = await provider.connection.getRecentBlockhash();
      const txn = new anchor.web3.Transaction().add(token);
      txn.recentBlockhash = recentBlock.blockhash;
      txn.feePayer = publicKey;

      const signature = await sendTransaction(txn, provider.connection);
      let transactionData = null;
      const MAX_RETRIES = 5;
      let RETRY_DELAY = 3000;
      let retries = 0;

      while (transactionData === null && retries < MAX_RETRIES) {
        try {
          transactionData = await provider.connection.getParsedTransaction(
            signature
          );
          retries += 1;
          if (transactionData === null && retries >= MAX_RETRIES) {
            new Error(
              "Unable to confirm the transaction after multiple attempts."
            );
            return {
              message:
                "Unable to confirm the transaction after multiple attempts.",
              type: "error",
            };
          }
        } catch (error) {
          console.error("Oops, an error has occurred!", error);
        }
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      }

      if (transactionData.meta.err !== null) {
        new Error(
          "The transaction failed due to low balance. Please try again."
        );
        return {
          message:
            "The transaction failed due to low balance. Please try again.",
          type: "error",
        };
      }

      await api.createToken({
        bonding_curve: BONDING_CURVE_PDA.toString(),
        mint: MINT_PDA.toString(),
        name: name,
        description: description,
        symbol: symbol,
        wallet_creator: publicKey.toBase58(),
        ipfs_url: uri,
        ipfs_filetype: "image",
        telegram,
        website,
        twitter,
      });

      return {
        message: "You've made a token, hustler!",
        type: "success",
        token,
        mint: MINT_PDA.toString(),
      };
    } catch (error) {
      console.error(error);
      return { message: "Oops, an error has occurred! 🥲", type: "error" };
    }
  };

  const EvenTrandingToken = async (
    data: Token,
    amount: number,
    eventTrading: "buy" | "sell",
    slippage: number,
    progress?: (value: string) => void
  ) => {
    progress("Getting Info...");
    const MINT_PDA = new anchor.web3.PublicKey(data.mint);
    const USER_ATA = anchor.utils.token.associatedAddress({
      mint: MINT_PDA,
      owner: publicKey,
    });
    const BONDING_CURVE_PDA = new anchor.web3.PublicKey(data.bonding_curve);

    const BONDING_CURVE_ATA = anchor.utils.token.associatedAddress({
      mint: MINT_PDA,
      owner: BONDING_CURVE_PDA,
    });

    const [CONFIG_PDA] = anchor.web3.PublicKey.findProgramAddressSync(
      [Buffer.from("config")],
      program.programId
    );

    const user = await program.account.config.fetch(CONFIG_PDA);

    if (slippage > 50) {
      progress("Slippage number no valid");
      return {
        message: "Slippage number no valid!",
        type: "error",
        txn: "",
      };
    }
    // const slippageAmount = amount * (slippage / 100);
    const bnAmount = new anchor.BN(amount * 10 ** 6);

    try {
      const txn =
        eventTrading === "sell"
          ? await program.methods
              .sell(
                bnAmount,
                // new anchor.BN((amount - slippageAmount) * LAMPORTS_PER_SOL)
                null
              )
              .accounts({
                user: publicKey,
                bondingCurve: BONDING_CURVE_PDA,
                bondingCurveAta: BONDING_CURVE_ATA,
                feesRecipient: user.feesRecipient,
                mint: MINT_PDA,
                config: CONFIG_PDA,
                userAta: USER_ATA,
              })
              .instruction()
          : eventTrading === "buy" &&
            (await program.methods
              .buy(
                bnAmount,
                // new anchor.BN((amount + slippageAmount) * LAMPORTS_PER_SOL)
                null
              )
              .accounts({
                user: publicKey,
                bondingCurve: BONDING_CURVE_PDA,
                bondingCurveAta: BONDING_CURVE_ATA,
                feesRecipient: user.feesRecipient,
                mint: MINT_PDA,
                config: CONFIG_PDA,
                userAta: USER_ATA,
              })
              .instruction());
      const recentBlock = await provider.connection.getRecentBlockhash();
      const transaction = new anchor.web3.Transaction().add(txn);
      transaction.recentBlockhash = recentBlock.blockhash;
      transaction.feePayer = publicKey;
      progress("Creating Transaction...");

      const signature = await sendTransaction(transaction, provider.connection);
      let transactionData = null;
      const MAX_RETRIES = 5;
      const RETRY_DELAY = 3000;
      let retries = 0;

      while (transactionData === null && retries < MAX_RETRIES) {
        try {
          transactionData = await provider.connection.getParsedTransaction(
            signature
          );
          retries += 1;
          if (transactionData === null && retries >= MAX_RETRIES) {
            new Error(
              "Unable to confirm the transaction after multiple attempts."
            );
            return {
              message:
                "Unable to confirm the transaction after multiple attempts.",
              type: "error",
            };
          }
        } catch (error) {
          console.error("Oops, an error has occurred!", error);
        }
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      }
      if (transactionData.meta.err !== null) {
        new Error(
          "The transaction failed due to low balance. Please try again."
        );
        return {
          message:
            "The transaction failed due to low balance. Please try again.",
          type: "error",
        };
      }

      progress("Saving Transaction...");
      return {
        message:
          eventTrading === "buy"
            ? "You just bought a stash!"
            : "You dumped, hopefully made it ",
        type: "success",
        txn,
      };
    } catch (error) {
      console.error(error);
      return {
        message:
          eventTrading === "buy"
            ? "Transaction failed, hustle harder!"
            : "Dumping your bag has failed",
        type: "error",
        txn: "",
      };
    }
  };

  const getBordingCurve = useCallback(
    async (bonding_curve: string, mint: string, owner: string) => {
      try {
        const BONDING_CURVE_PDA = new anchor.web3.PublicKey(bonding_curve);
        const bordin = await program.account.bondingCurve.fetch(
          BONDING_CURVE_PDA
        );
        const dataHolder = await provider.connection.getTokenLargestAccounts(
          new anchor.web3.PublicKey(mint)
        );
        const dataAcoount = await connection.getMultipleParsedAccounts([
          ...dataHolder.value.map((row) => row.address),
        ]);
        const owners = dataAcoount.value.map(
          (row: any) => row.data.parsed.info.owner
        );
        const dataHol: any = dataHolder.value;
        for (let i = 0; i < dataHol.length; i++) {
          dataHol[i].address = owners[i];
          dataHol[i].hypelink = owners[i];
          if (dataHol[i].address === bonding_curve) {
            dataHol[i].address = `${dataHol[i].address.slice(
              0,
              10
            )} 🤖 (BONDING CURVE)`;
            dataHol[
              i
            ].hypelink = `https://solscan.io/token/${dataHol[i].hypelink}`;
          } else if (dataHol[i].address === owner) {
            dataHol[i].address = `${dataHol[i].address.slice(0, 10)} 👨‍💻 (DEV)`;
            dataHol[
              i
            ].hypelink = `https://solscan.io/account/${dataHol[i].hypelink}`;
          } else {
            dataHol[i].address = dataHol[i].address.slice(0, 10);
            dataHol[
              i
            ].hypelink = `https://solscan.io/account/${dataHol[i].hypelink}`;
          }
        }
        return { bordin, dataHolder: dataHol };
      } catch (error) {
        console.error(Error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getBalanceToke = useCallback(
    async function getTokenBalanceWeb3(mint: string, pubkey: string) {
      const address2 = getAssociatedTokenAddressSync(
        new anchor.web3.PublicKey(pubkey),
        new anchor.web3.PublicKey(mint)
      );
      const info = await provider.connection.getTokenAccountBalance(address2);
      if (info.value.uiAmount == null) throw new Error("No balance found");
      console.log("Balance (using Solana-Web3.js): ", info.value.uiAmount);
      return info.value.uiAmount;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    program,
    programId,
    createToken,
    EvenTrandingToken,
    getBordingCurve,
    getBalanceToke,
  };
}
