import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./Layouts/Navbar";
import React, { useCallback, useEffect } from "react";
import Market from "./Pages/Market";
import Create from "./Pages/Create";
import Home from "./Pages/Home";
import { Toaster } from "react-hot-toast";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletError } from "@solana/wallet-adapter-base";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, Analytics } from "firebase/analytics";
import Profile from "./Pages/Profile";
import Staking from "./Pages/Staking";
import Swap from "./Pages/Swap";

const firebaseConfig = {
  apiKey: "AIzaSyAVNe-jDv6dvdQQvpO2xwFSnnG3MEu8T_M",
  authDomain: "sbf-fun.firebaseapp.com",
  projectId: "sbf-fun",
  storageBucket: "sbf-fun.appspot.com",
  messagingSenderId: "261926068062",
  appId: "1:261926068062:web:34e21eef22372454a879cf",
  measurementId: "G-RP7SZDEV2B",
};

export let analytics: Analytics | undefined;

if (typeof window !== "undefined") {
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

const App = () => {
  const endpoint = process.env.API_MAINEMET;

  const onError = useCallback((error: WalletError) => {
    console.error(error);
  }, []);
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view");
    }
  }, []);
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={[]} onError={onError} autoConnect>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Navbar />}>
              <Route index element={<Home />} />
              <Route path="create" element={<Create />} />
              <Route path="market/:mint" element={<Market />} />
              <Route path="profile/:pubkey" element={<Profile />} />
              <Route path="staking" element={<Staking />} />
              <Route path="swap" element={<Swap />} />
              <Route path="*" element={<Navigate to={"/"} />} />
            </Route>
          </Routes>
        </HashRouter>
        <Toaster position="top-center" reverseOrder={false} />
      </WalletProvider>{" "}
    </ConnectionProvider>
  );
};

export default App;
