import { PublicKey } from "@solana/web3.js";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Token } from "src/Api/interface";
import S3FileManager from "src/Class/controller";

export const CreateImage = async (
  file: File,
  publicKey: string,
  loadingProgress?: (e: string) => void
): Promise<string | null> => {
  if (!file.type.startsWith("image/")) {
    toast.error("The file is not an image.", {
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
    console.log("errro");
    return null;
  }

  const fileManager = new S3FileManager(publicKey && publicKey);
  try {
    const fileURL = await fileManager.uploadFile(file, (progress) => {
      if (loadingProgress) {
        loadingProgress(`image progress: ${progress}%`);
      }
    });
    return fileURL;
  } catch (error) {
    loadingProgress("Error uploading the file");
    toast.error("Error uploading the file", {
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
    console.error("Error", error);
  }
};

export const CreatesImage = async (
  files: File[],
  publicKey: string,
  loadingProgress?: (e: string) => void
): Promise<string[]> => {
  const fileManager = new S3FileManager(publicKey && publicKey);
  const option: string[] = [];

  try {
    for (let i = 0; i < files.length; i++) {
      if (!files[i].type.startsWith("image/")) {
        toast.error(`File #${i + 1} is not an image.`, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        return [];
      }
      const fileURL = await fileManager.uploadFile(files[i], (progress) => {
        if (loadingProgress) {
          loadingProgress(`images #${i + 1} progress: ${progress}%`);
        }
      });
      option.push(fileURL);
    }
    return option;
  } catch (error) {
    toast.error("Error uploading the files", {
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
    console.error("Error", error);
  }
};

export const acortarURL = async (url: string) => {
  try {
    const response = await axios.get(
      `https://tinyurl.com/api-create.php?url=${encodeURIComponent(url)}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al acortar la URL:", error);
    return null;
  }
};

export function formatNumber(number: number) {
  const abs = Math.abs(number);
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (abs >= billion) {
    return (number / billion).toFixed(0) + "B";
  } else if (abs >= million) {
    return (number / million).toFixed(0) + "M";
  } else if (abs >= thousand) {
    return (number / thousand).toFixed(0) + "K";
  } else {
    return number;
  }
}

export const shuffleArray = (array: Token[], previousFirstIndex?: number) => {
  const shuffledArray = [...array];

  if (previousFirstIndex !== undefined && previousFirstIndex > 0) {
    const previousFirstItem = shuffledArray.splice(previousFirstIndex, 1)[0];
    shuffledArray.splice(1, 0, previousFirstItem);
  }

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

export function fillMissingData(data) {
  const filledData = [];
  let prevObj = null;

  data.forEach((obj) => {
    const timestamp = new Date(obj.time * 1000); // Convertir el tiempo a milisegundos
    const prevTimestamp = prevObj ? new Date(prevObj.time * 1000) : timestamp;

    // Fill missing data
    while (prevTimestamp < timestamp) {
      const newObj = { ...prevObj };
      newObj.time = new Date(prevTimestamp.getTime() + 60000).toISOString(); // Agregar un minuto (60000 ms)
      filledData.push(newObj);

      prevTimestamp.setMinutes(prevTimestamp.getMinutes() + 1);
    }

    filledData.push(obj);
    prevObj = obj;
  });

  return filledData;
}

export function addHTTPS(url: string) {
  if (!/^https:\/\//.test(url)) {
    return "https://" + url;
  }
  return url;
}

const TOKEN_PROGRAM_ID = new PublicKey(
  "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
);
const ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

export function getAssociatedTokenAddressSync(
  owner: PublicKey,
  mint: PublicKey
): PublicKey {
  const [address] = PublicKey.findProgramAddressSync(
    [owner.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
    ASSOCIATED_TOKEN_PROGRAM_ID
  );
  return address;
}

export const handleImageChange = (file: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          const img = new Image();
          img.src = e.target.result as string;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const width = 500;
            const height = 500;

            canvas.width = width;
            canvas.height = height;

            // Draw the image onto the canvas with the desired dimensions
            ctx?.drawImage(img, 0, 0, width, height);

            // Convert the canvas back to a data URL or Blob
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error("Image conversion failed."));
                }
              },
              "image/jpeg",
              0.95
            );
          };
          img.onerror = () => reject(new Error("Image load failed."));
        } else {
          reject(new Error("File reading failed."));
        }
      };
      reader.onerror = () => reject(new Error("File reading failed."));
      reader.readAsDataURL(file);
    } else {
      reject(new Error("No file provided."));
    }
  });
};

export const POOL_TOKEN_AMOUNT = 206900000;

export const calMarketPrice = (
  total_supply: number,
  sol_price: number,
  initialsol: number
) => {
  const POOL_SOL_AMOUNT = (initialsol * 70) / 25;
  return (POOL_SOL_AMOUNT / POOL_TOKEN_AMOUNT) * total_supply * sol_price;
};
