import { useWallet } from "@solana/wallet-adapter-react";
import React, { useEffect } from "react";
import { memo, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Comments } from "src/Api/interface";
import { getApiMethod } from "src/Api/token";

export const CardComment = memo<{
  row: Comments;
  onClick: () => void;
  syncData?: () => void;
  userColor?: string;
}>(({ row, onClick, syncData, userColor }) => {
  const { publicKey } = useWallet();
  const api = new getApiMethod();
  const [follow, setFollow] = useState(row.likes?.count || 0);

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (row.likes && typeof row.likes.count === "number") {
      setFollow(row.likes.count);
    }
  }, [row.likes]);

  return (
    <div
      id={row.id && row.id.slice(0, 8)}
      className="bg-white outline outline-2 outline-green_custom shadow-md w-full flex flex-col gap-1 p-2 rounded-lg font-pricedown relative line-clamp-6"
    >
      <div className="flex gap-2 text-md font-normal justify-start items-center w-full max-sm:items-start">
        <Link
          to={`/profile/${row?.pubkey_user?.pubkey}`}
          className={`flex justify-center items-center bg-green_custom rounded-full hover:bg-green_custom transition-all ${userColor}`}
        >
          <img
            src={row?.pubkey_user?.image}
            alt={row?.pubkey_user?.image}
            className="w-20 h-20 rounded-lg"
          />
        </Link>
        <div>
          <span
            className={`font-normal uppercase ${userColor} px-2 rounded-full text-white`}
          >
            {row.pubkey_user?.username}
          </span>
          <p className="text-sm text-slate-400">
            {new Date(row.created_at).toLocaleString("en-US", {
              dateStyle: "long",
              timeStyle: "medium",
            })}
          </p>
          <button
            type="button"
            onClick={onClick}
            className="font-normal underline text-neutral-400"
          >
            <span className="text-green_custom">[reply] </span> #
            {row.id && row.id.slice(0, 8)}{" "}
          </button>
        </div>

        <button
          className="group rounded-lg p-1.5 absolute top-3 right-3 group disabled:hover:outline-0 disabled:outline-0"
          onClick={async () => {
            if (!publicKey)
              return toast.error("YOU NEED TO CONNECT A WALLET, ROOKIE", {
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            try {
              setFollow(row.likes.count + 1);
              await api.createFollowers({
                pubkey: row.id,
                followerkey: publicKey.toString(),
              });
              toast.success("You've expressed that you liked it", {
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
              return syncData();
            } catch (error) {
              setFollow(row.likes.count);
              console.log(error);
              toast.error("Failed to like", {
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
            }
          }}
          disabled={
            follow !== row.likes?.count ||
            (publicKey &&
              row.likes.followers.find(
                (x) => x.followerkey === publicKey.toString()
              ))
              ? true
              : false
          }
        >
          <div className="relative flex justify-center items-center">
            <span className="text-white absolute top-1.5 text-xs">
              {follow}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="28"
              height="28"
              className={`group-hover:fill-greenbg-green_custom ${
                follow !== row.likes?.count ||
                (publicKey &&
                  row.likes.followers.find(
                    (x) => x.followerkey === publicKey.toString()
                  ))
                  ? "fill-green_custom"
                  : "fill-red-500"
              }`}
            >
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
            </svg>
          </div>
        </button>
      </div>
      {row.content && row.content.split(",")[0].startsWith("#") && (
        <p className="uppercase">
          Mention:{" "}
          <button
            className="underline text-greenbg-green_custom"
            type="button"
            onClick={() => {
              scrollToElement(
                row.content.split(",")[0].startsWith("#") &&
                  row.content.split(",")[0].slice(1)
              );
            }}
          >
            {row.content.split(",")[0].startsWith("#") &&
              row.content.split(",")[0]}
          </button>
        </p>
      )}
      <p className="text-sm font-poetsen text-justify">
        {" "}
        {row.content && row.content.split(",")[0].startsWith("#")
          ? row.content.split(",")[1]
          : row.content}
      </p>
      {row.related_images && (
        <div className="grid grid-cols-4 gap-4 place-items-center w-full">
          {row.related_images.map((image) => (
            <>
              <img
                src={image.image}
                alt={image.image}
                className="w-28 h-28 border rounded-lg"
              />
            </>
          ))}
        </div>
      )}
    </div>
  );
});
