import React, { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

interface MenuProps {
  menu: {
    name: string;
    class: string;
    icon?: string;
    image?: string;
    onClick?: () => void;
  }[];
  buttonCenter?: {
    class: string;
    icon: string;
    name: string;
    onClick?: () => void;
  };
  onClick?: (value: { name: string; active: boolean }) => void;
  children?: React.ReactNode;
}

const TabMenu = memo<MenuProps>((props) => {
  const { menu, buttonCenter, onClick, children } = props;
  const { class: classP, icon, name } = buttonCenter;
  const location = useLocation();
  const [active, setActive] = React.useState(0);

  useEffect(() => {
    if (location.pathname.split("/")[1] === "create") {
      setActive(1);
    } else if (location.pathname === "/") {
      setActive(0);
    } else if (location.pathname.split("/")[1] === "profile") {
      setActive(3);
    } else if (location.pathname.split("/")[1] === "market") {
      setActive(2);
    }
  }, [location]);
  return (
    <motion.div
      initial={{ opacity: 0, bottom: -100, position: "fixed" }}
      animate={{ opacity: 1, bottom: 0, position: "fixed" }}
      exit={{ opacity: 0 }}
      transition={{ delay: 1 }}
      className="fixed bottom-0 left-0 w-full z-40"
    >
      <div className="flex-col items-center justify-center relative lg:hidden md:hidden max-sm:flex w-full">
        <div
          className={`grid grid-cols-${
            menu.length || 4
          } divide-x-2 divide-green_custom outline outline-2 outline-green_custom w-full bg-black/90 text-white`}
        >
          {menu.map((row, index) => (
            <div
              key={index}
              className={`flex flex-col gap-1 justify-center py-1 items-center transition-all ${
                row.class
              } ${active === index && "bg-green_custom text-white"}`}
              onClick={() => {
                setActive(index);
                onClick({ name: row.name, active: true });
                row.onClick && row.onClick();
              }}
            >
              {row.image ? (
                <img
                  src={row.image}
                  alt={row.image}
                  className="w-10 h-10 rounded-full"
                />
              ) : (
                <i className={`${row.icon} text-3xl`}></i>
              )}
              <h2 className="text-xs font-pricedown uppercase">{row.name}</h2>
            </div>
          ))}
        </div>
        <div className="absolute -top-8">
          <div
            className={`flex flex-col gap-2 text-4xl rounded-full ${
              !children
                ? `p-4 py-3.5  ${
                    active === 4
                      ? "bg-green_custom text-white"
                      : "text-black bg-white"
                  }`
                : "bg-white"
            } justify-center items-center  ${classP} hover:outline-4 outline outline-black`}
            onClick={() => {
              setActive(4);
              onClick({ name: name, active: true });
              buttonCenter.onClick && buttonCenter.onClick();
            }}
          >
            {children ? children : <i className={icon}></i>}
          </div>
        </div>
      </div>
    </motion.div>
  );
});

export default TabMenu;
