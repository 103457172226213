import React, { CSSProperties, MouseEvent, ReactElement } from "react";
import { User } from "src/Api/interface";
import { BaseWalletMultiButton } from "./BaseWalletMutiButton";

const LABELS = {
  "change-wallet": "Change wallet",
  connecting: "Connecting ...",
  "copy-address": "Copy address",
  copied: "Copied",
  disconnect: "Disconnect",
  "has-wallet": "Connect",
  "no-wallet": "  Connect wallet",
  edit: "Edit Profile",
  profile: "Profile",
  referi: "Share referral link",
} as const;

export type ButtonProps = {
  className?: string;
  disabled?: boolean;
  endIcon?: ReactElement;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  startIcon?: ReactElement;
  style?: CSSProperties;
  tabIndex?: number;
  openModal?: () => void;
  dataUser: User;
  isMobile?: boolean;
  id?: string;
} & {
  children?: React.ReactNode;
};

export function BtnConnect(props: ButtonProps) {
  return <BaseWalletMultiButton {...props} labels={LABELS} />;
}
