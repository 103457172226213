import React, { memo } from "react";

interface ButtonProps {
  label?: string | React.ReactNode;
  color?: string;
  className?: string;
  img?: string;
  type?: "submit" | "reset" | "button";
  classImg?: string;
  onClick?: () => void;
  icon?: string;
  loading?: boolean;
  loadingMesaage?: string;
  loadingClassName?: string;
}

const Button = memo<ButtonProps>(
  ({
    label,
    color,
    className,
    img,
    type,
    classImg,
    loading,
    loadingMesaage,
    loadingClassName,
    icon,
    onClick,
  }) => {
    let buttonClass =
      "outline outline-2 outline-green_custom rounded-lg font-pricedown uppercase tracking-wider text-xl bg-transparent hover:outline-4 relative";
    if (color) {
      buttonClass = color;
    }

    if (loading) {
      return (
        <div
          className={`${loadingClassName} flex justify-center items-center gap-2 text-4xl font-pricedown uppercase tracking-wider max-sm:text-lg md:text-xl `}
        >
          <i className="fas fa-spinner animate-spin"></i>
          <p>{loadingMesaage}</p>
        </div>
      );
    }
    return (
      <button
        className={`${buttonClass} ${className} p-2 px-6 rounded-full font-semibold text-md transition-all flex justify-center items-center`}
        type={type}
        onClick={onClick}
      >
        {img && (
          <span className="absolute left-3">
            <img
              src={img}
              alt={img}
              className={`${classImg} w-6 rounded-full`}
            />
          </span>
        )}
        {icon && <i className={`${icon} mr-2`} />}
        {label}
      </button>
    );
  }
);

export default Button;
