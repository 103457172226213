import React, { FunctionComponent } from "react";
import Button from "../Components/Button";

import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import Input from "src/Components/Inputs";

const Swap: FunctionComponent = () => {
  const {
    register,
    formState: { errors },
  } = useForm();
  return (
    <div className="relative">
      <div className="flex flex-col justify-center items-center gap-6 w-full">
        <motion.div
          initial={{ opacity: 0, right: 100, position: "relative" }}
          animate={{ opacity: 1, right: 0, position: "relative" }}
          exit={{ opacity: 0 }}
          transition={{ delay: 1 }}
          className="p-4 max-sm:px-6 max-sm:pt-14 pt-16 flex justify-center items-center  max-sm:w-full w-6/12 flex-col gap-4 text-center"
        >
          <h2 className="font-pricedown text-4xl max-sm:text-4xl tracking-wider text-gradiant">
            <span>Token Migration</span>
            <br className="max-sm:hidden" />
            <span className="text-xl">
              The token swap rate for SBF to Apollo is 1,531,299.3387 SBF to
              Apollo. Token swap will be live until September 10 11:59 PM
              eastern time. A new liquidity pool will be created with locked
              liquidity on Meteora. After the swap period ends, the pool will be
              seeded with 200 Apollo/~20,000 USDC worth of SOL.
            </span>
          </h2>

          <form className="flex flex-col gap-4">
            <div className="flex justify-between items-center gap-6">
              <Input
                name="cantidad"
                register={register}
                errors={errors.cantidad}
                placeholder="Amount"
              />
              <i className="fa-solid fa-arrow-right text-2xl"></i>
              <Input
                name="recive"
                register={register}
                errors={errors.recive}
                placeholder="Receive"
                readOnly
              />
            </div>
            <div className="w-full flex justify-center items-center">
              <Button
                label={"swap to apollo"}
                color="bg-white hover:bg-green_custom hover:text-white text-gray_custom border-green_custom rounded-md"
                className={
                  "px-16 text-4xl max-sm:text-2xl uppercase font-pricedown tracking-wider hover:outline-4 outline outline-2 outline-green_custom"
                }
                type="button"
              />
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default Swap;
