import React, { useCallback, useEffect, useRef, useState } from "react";
import TradingView from "../charting_library.standalone.js";
import { Token } from "src/Api/interface";
import datafeed from "./datafeed";

interface Props {
  token: Token;
  dataFeed: any;
}

export const TVChartContainer = ({
  mint,
  price,
}: {
  mint: string;
  price: string;
}) => {
  const [data, setData] = useState<Props | null>(null);
  const chartContainerRef = useRef();

  const getTokenDataInit = useCallback(async () => {
    const data = await datafeed(mint, price);
    return setData(data);
  }, [mint, price]);

  useEffect(() => {
    getTokenDataInit();
  }, [getTokenDataInit]);

  useEffect(() => {
    if (data) {
      const { dataFeed, token } = data;
      const tvWidget = new TradingView.widget({
        library_path: "/charting_library/bundles",
        // debug: true, // uncomment this line to see Library errors and warnings in the console
        fullscreen: true,
        symbol: token.symbol,
        interval: "5",
        container: chartContainerRef.current,
        datafeed: dataFeed,
        locale: "en",
        disabled_features: [],
        enabled_features: [],
        theme: "light",
        autoResizeChart: true,
      });

      // tvWidget.onChartReady(() => {
      //   tvWidget.headerReady().then(() => {
      //     const button = tvWidget.createButton();
      //     button.setAttribute("title", "Click to show a notification popup");
      //     button.classList.add("apply-common-tooltip");
      //     button.addEventListener("click", () =>
      //       tvWidget.showNoticeDialog({
      //         title: "Notification",
      //         body: "TradingView Charting Library API works correctly",
      //         callback: () => {
      //           console.log("Noticed!");
      //         },
      //       })
      //     );

      //     button.innerHTML = "Check API";
      //   });
      // });

      return () => {
        tvWidget.remove();
      };
    }
  }, [data]);

  return (
    <div
      ref={chartContainerRef}
      className="w-full"
      style={{ height: "44rem" }}
    />
  );
};
