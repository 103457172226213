import React, { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Token } from "src/Api/interface";
import { getApiMethod } from "src/Api/token";
import FormSearch from "src/Components/FormSearch";
import Jail from "src/Components/Jail";
import { formatNumber } from "src/utils/funtions";
import Button from "../Components/Button";
import Card from "../Components/Card";
import Pagination from "../Components/Pagination";
import { motion } from "framer-motion";

const Home: FunctionComponent = () => {
  const [data, setData] = useState<Token[] | []>([]);
  const [jail, setJail] = useState(false);
  const [top, setTop] = useState<Token | null>(null);
  const renderCard = (item: Token, index: number) => {
    const isMain = index === 0;

    let className = "";
    if (isMain) {
      className +=
        "col-span-2 max-sm:col-span-1 outline-green_custom outline-2 bg-transparent animate-bounce outline-none hover:outline-none";
    }
    if (item.created_at) {
      const currentDate = new Date();
      const fifteenMinutesAgo = new Date();
      fifteenMinutesAgo.setMinutes(fifteenMinutesAgo.getMinutes() - 15);

      const tokenDate = new Date(item.created_at);

      if (tokenDate >= fifteenMinutesAgo && tokenDate <= currentDate) {
        className += `newCoin delay-${index}`;
      }
    }

    if (!isMain) {
      className += "hover:outline-4 hover:outline-green_custom";
    }
    if (index === 0) {
      return (
        <Card
          key={index}
          image={top.ipfs_url}
          create={top.wallet_creator}
          makertcap={`${formatNumber(Number(top.marketcap))}`}
          replies={top.description}
          name={top.name}
          mint={top.mint}
          symbol={top.symbol}
          presito={true}
          className={className}
        />
      );
    }

    return (
      <Card
        key={index}
        image={item.ipfs_url}
        create={item.wallet_creator}
        makertcap={`${formatNumber(Number(item.marketcap))}`}
        replies={item.description}
        name={item.name}
        mint={item.mint}
        symbol={item.symbol}
        // presito={isMain}
        className={className}
      />
    );
  };
  useEffect(() => {
    document.title = "Apollo Finance";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Launch your own coin, locked liquidity and earn trading fees"
      );
    }
    const favicon = document.querySelector<HTMLLinkElement>('link[rel="icon"]');
    if (favicon) {
      favicon.href = `/images/logo.jpeg`;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setJail(true);
    }, 0);

    const api = new getApiMethod();
    let localData: Token[] = [];

    async function updateLocalData() {
      try {
        const newData = await api.getTokens();
        const market = await api.getTokenOrdernByMarkecap();
        setTop(market[0]);
        localData = newData.filter((x) => x.mint !== market[0].mint);
        setData([market[0], ...localData]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    updateLocalData();
  }, []);

  return (
    <div className="relative">
      {jail && <Jail />}
      <div className="flex flex-col justify-center items-start gap-6 w-full">
        <motion.div
          initial={{ opacity: 0, right: 100, position: "relative" }}
          animate={{ opacity: 1, right: 0, position: "relative" }}
          exit={{ opacity: 0 }}
          transition={{ delay: 1 }}
          className="p-4 max-sm:px-6 max-sm:pt-14 pt-16 flex flex-col gap-4"
        >
          <h2 className="font-pricedown text-7xl max-sm:text-4xl tracking-wider text-gradiant">
            <span>START A COIN</span>
            <br className="max-sm:hidden" /> <span> SHOOT FOR THE MOON.</span>
          </h2>
          <Link to={"/create"} className="w-6/12 md:w-8/12 max-sm:w-full">
            <Button
              label={"start a new coin"}
              color="bg-white hover:bg-green_custom hover:text-white text-gray_custom border-green_custom rounded-md"
              className={
                "px-16 text-4xl max-sm:text-2xl uppercase font-pricedown tracking-wider hover:outline-4 outline outline-2 outline-green_custom"
              }
              type="button"
            />
          </Link>
        </motion.div>

        <div
          className={`flex flex-col gap-4 justify-center items-start max-sm:items-center ${
            data && data.length !== 0 && "mt-44"
          } `}
        >
          <FormSearch
            onChange={(value) => {
              if (value.length === 0) {
                setData([]);
              } else {
                const da = value.filter((x) => x.mint !== top.mint);
                setData([]);
                setTimeout(() => {
                  setData([top, ...da]);
                }, 0);
              }
            }}
          />
          {data && data.length !== 0 && (
            <>
              <Pagination
                itemsPerPage={15}
                data={data}
                renderItem={renderCard}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
