import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

interface Image {
  id: string;
  url: string;
  alt: string;
}

interface RandomImageDropdownProps {
  onChange: (selectedImage: string) => void;
  className?: string;
  random?: boolean;
  count?: number;
}

const RandomImageDropdown = ({
  onChange,
  className,
  random,
  count,
}: RandomImageDropdownProps) => {
  const [images, setImages] = useState<Image[]>([]);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const fetchRandomImages = useCallback(async () => {
    try {
      const requests = Array.from({ length: count || 12 }, () =>
        axios.get(
          "https://source.unsplash.com/featured/?horror,scary,creepy,spooky,jail,prison,prisoner,prisoners,scared"
        )
      );

      const responses = await Promise.all(requests);
      const newImages: Image[] = responses.map((response) => {
        const imageUrl = response.request.responseURL;
        return {
          id: "random-" + Math.random().toString(36).substring(7),
          url: imageUrl,
          alt: "Random Image",
        };
      });

      setImages(newImages);
    } catch (error) {
      console.error("Error fetching random images:", error);
    }
  }, [count]);

  useEffect(() => {
    fetchRandomImages();
  }, [fetchRandomImages]);

  const handleSelectImage = (image: Image) => {
    setSelectedImage(image);
    image && onChange(image.url);
  };

  const selectRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImage = images[randomIndex];
    handleSelectImage(randomImage);
  };

  useEffect(() => {
    if (random) {
      selectRandomImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [random]);

  if (images.length < 3) {
    return (
      <div>
        <i className="fas fa-spinner animate-spin text-xl"></i>
      </div>
    );
  }

  return (
    <div
      className={`${className} grid grid-cols-4 max-sm:grid-cols-2 gap-4 dark:bg-neutral-700 p-2 rounded-md border dark:border-white`}
    >
      {images.map((image, index) => (
        <div
          key={index}
          onClick={() => handleSelectImage(image)}
          className={`${
            selectedImage && selectedImage.id === image.id
              ? "cursor-none dark:border-orange-500 border rounded-md"
              : "cursor-pointer"
          }`}
        >
          <img
            src={image.url}
            alt={image.alt}
            className="w-full h-28 rounded-md"
          />
        </div>
      ))}
    </div>
  );
};

export default RandomImageDropdown;
